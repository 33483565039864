import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

    AlertError,
} from '../../components/Utility/MyString';

const Job_Context = React.createContext();


// const ServiceApi = BaseApi + "/api/sales/db_save";





export default class Job_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                strsearch: ''
                , job_id: ''
                , creatorname: ''
                , creatorteam: ''
                , job_start_date: ''
                , job_end_date: ''
                , job_name1: ''
                , job_details1: ''
                , product: ''
                , targetgroup: ''
                , sourceofcus: ''
                , content_msg: ''
                , media_type: ''
                , channel: ''
                , job_target1: ''
                , job_target2: ''
                , job_target3: ''
                , job_target4: ''
                , job_target5: ''
                , job_target6: ''
                , job_target7: ''
                , job_target8: ''
                , job_status1: ''
                , companycode: ''
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    strsearch: ''
                    , job_id: ''
                    , creatorname: ''
                    , creatorteam: ''
                    , job_start_date: ''
                    , job_end_date: ''
                    , job_name1: ''
                    , job_details1: ''
                    , product: ''
                    , targetgroup: ''
                    , sourceofcus: ''
                    , content_msg: ''
                    , media_type: ''
                    , channel: ''
                    , job_target1: ''
                    , job_target2: ''
                    , job_target3: ''
                    , job_target4: ''
                    , job_target5: ''
                    , job_target6: ''
                    , job_target7: ''
                    , job_target8: ''
                    , job_status1: ''
                    , companycode: '',
                    totalrecord: 1
                }], 
                itempage_job: [
                    {
                        strsearch: ''
                        , job_id: ''
                        , creatorname: ''
                        , creatorteam: ''
                        , job_start_date: ''
                        , job_end_date: ''
                        , job_name1: ''
                        , job_details1: ''
                        , product: ''
                        , targetgroup: ''
                        , sourceofcus: ''
                        , content_msg: ''
                        , media_type: ''
                        , channel: ''
                        , job_target1: ''
                        , job_target2: ''
                        , job_target3: ''
                        , job_target4: ''
                        , job_target5: ''
                        , job_target6: ''
                        , job_target7: ''
                        , job_target8: ''
                        , job_status1: ''
                        , companycode: '',
                        totalrecord: 1
                    }],
    
           
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };
   

    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageJob(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageJob(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageJob(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPageJob(callBack);
        }, 200)

    }




    getRunningJob = async (code, callBack) => {
        try {

            //alert("product context getpage -> " + this.state.strsearch);

            let code = "JOB"

            let page = {
                code: code

            }

            //alert("inbox context get page")
            let url = BaseApi + "/api/sales/db_getrunningjob";


            //  alert("context getpage ->" + JSON.stringify(page));

            await axios.post(url, { data: page })
                .then((response) => {

                    //   alert("context get running ->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: {
                            item_no: response.data[0].item_no,
                            job_id: response.data[0].job_id,
                            jobdate: response.data[0].jobdate,
                            jobtime: response.data[0].jobtime,

                        },
                        dataitem: [],
                        datadealer: []
                    })

                    let xobj = this
                    setTimeout(function () {
                        callBack(xobj.state.data)
                    }, 100)

                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };




    getPageJob = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }

            //    alert("inbox context get page")

            let url = BaseApi + "/api/sales/db_pagejob";

            // alert("context   getPageJob ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {


                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    saveData = async (xdata, callBack) => {

        try {

            let job_id = sessionStorage.getItem("job_id")
            let creatorname = sessionStorage.getItem("userid")
            let creatorteam = sessionStorage.getItem("team_id")
            let job_start_date = sessionStorage.getItem("job_start_date")
            let job_end_date = sessionStorage.getItem("job_end_date")
            let job_name1 = sessionStorage.getItem("job_name1")
            let job_details1 = sessionStorage.getItem("job_details1")
            let product = sessionStorage.getItem("product")
            let targetgroup = sessionStorage.getItem("targetgroup")
            let sourceofcus = sessionStorage.getItem("sourceofcus")
            let content_msg = sessionStorage.getItem("content_msg")
            let media_type = sessionStorage.getItem("media_type")
            let channel = sessionStorage.getItem("channel")
            let job_target1 = sessionStorage.getItem("job_target1")
            let job_target2 = sessionStorage.getItem("job_target2")
            let job_target3 = sessionStorage.getItem("job_target3")
            let job_target4 = sessionStorage.getItem("job_target4")
            let job_target5 = sessionStorage.getItem("job_target5")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")
            let sharejob = sessionStorage.getItem("sharejob")
            let job_status1 = sessionStorage.getItem("job_status1")

            let xdata = {

                job_id: job_id,
                creatorname: creatorname,
                creatorteam: creatorteam,
                job_start_date: job_start_date,
                job_end_date: job_end_date,
                job_name1: job_name1,
                job_details1: job_details1,
                product: product,
                targetgroup: targetgroup,
                sourceofcus: sourceofcus,
                content_msg: content_msg,
                media_type: media_type,
                channel: channel,
                job_target1: job_target1,
                job_target2: job_target2,
                job_target3: job_target3,
                job_target4: job_target4,
                job_target5: job_target5,
                companycode: companycode,
                userid: userid,
                sharejob: sharejob,
                job_status1: job_status1
            }



            let url = BaseApi + "/api/sales/db_save_job";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)

            //  alert(code)

            let page = {
                job_id: sessionStorage.getItem("job_id"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }

            // alert("context getdata  jobdata   ->" + JSON.stringify(page));


            // await axios.post(BaseApi + "/api/sales/db_getjobbyid", { params: { code: code } })

            await axios.post(BaseApi + "/api/sales/db_getjobbyid", { data: page })



                .then((response) => {

                    //    AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };





    shareJobToTeam = async (xdata, callBack) => {

        try {

            let job_id = sessionStorage.getItem("job_id")
            let sharejob = sessionStorage.getItem("sharejob")
            let userid = sessionStorage.getItem("userid")

            let xdata = {

                job_id: job_id,
                userid: userid,
                sharejob: sharejob
            }



            let url = BaseApi + "/api/sales/db_share_job";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    getTargetGroup = async (callBack) => {
        try {


            let page = {
                job_group_id: sessionStorage.getItem("job_group_id"),
                input_table1: sessionStorage.getItem("input_table1")

            }





            await axios.post(BaseApi + "/api/sales/getjob_itemselect", { data: page })
                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)
                })




        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };






    CheckJobnameDuplicate = async (xdata, callBack) => {

        try {

            let job_id = sessionStorage.getItem("job_id")
            let job_name1 = sessionStorage.getItem("job_name1")
            let userid = sessionStorage.getItem("userid")

            let xdata = {

                job_id: job_id,
                job_name1: job_name1,
                userid: userid

            }



            let url = BaseApi + "/api/sales/db_job_checkduplicate";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })
                .then((response) => {


                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

 

    getjobs = async (callBack) => {

        try { 
             let page = {
                userid :sessionStorage.getItem("userid")  
                 
               }
                

            await axios.post(BaseApi + "/api/sales/getjobs", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                   
                    this.setState({
                        ...this.state,               
                        itempage_job : response.data,              
                    });
                   
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

     
    handleChange = (str1, callBack) => {

  

        this.setState({
 
            ...this.state,
         
            strsearch:str1.strsearch,
            pageindex: 1

        });
        let obj = this
        setTimeout(function () {
         
            obj.getPageJob(callBack)
        }, 100)

    }


    render() {
        return (
            <Job_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData, 
                    saveData: this.saveData,
                    getData: this.getData,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    getjobs: this.getjobs,
                    getPageJob: this.getPageJob, 
                    shareJobToTeam: this.shareJobToTeam,
                    getTargetGroup: this.getTargetGroup,
                    CheckJobnameDuplicate: this.CheckJobnameDuplicate,
                    getRunningJob: this.getRunningJob

                }}
            >
                {this.props.children}
            </Job_Context.Provider>

        );
    }
}
const Job_Consumer = Job_Context.Consumer;

export { Job_Provider, Job_Consumer, Job_Context };

export function withJob_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Job_Consumer>
                {value => <Component {...props} context={value} />}
            </Job_Consumer>
        );
    };
}

