import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
import { message } from 'antd'
import {
    AlertCompleSave,
    AlertError,
} from '../components/Utility/MyString';

const Productdetailbyregister_SurekContext = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/stock/Productdetail_surek";





export default class Productdetailbyregister_SurekProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                vendorid: '',
                vendorname: '',
                curentaddress: '',
                telephone: '',
                faxphone: '',
                mobilephone: '',
                // activestatus: '1',
                vendornote: '01'
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    vendorid: '',
                    title: '',
                    itemnname: '',
                    telephone: '',
                    faxphone: '',
                    mobilephone: '',
                    vendornote: '01',
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            companycode: '01',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (id,callBack) => {
        try {


        // alert('get data productlistbybrand id 333 =>' + id);
        // alert("product context getpage productlistbybrand  1-> " + this.state.strfromdate);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: id,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'ssa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stock/Productliststock_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context report getpage productlistbybrand 3  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })


        } catch (error) {
            AlertError("error->" + error.message);
            // callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getMenubyUserid = async (userid, callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.post(url, { data: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getData = async (code, callBack) => {
        try {

            // alert("sssssqqqqqqq"  + code);
            //alert("service api ->" + ServiceApi)


         

            await axios.get(ServiceApi, { params: { code: code } })
                .then((response) => {

                // AlertError("context from server  getdata ->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };


    // getPageModel = async (id,callBack) => {
    //     try {

 
    //         let page = {
    //             pageindex: this.state.pageindex,
    //             pagesize: this.state.pagesize,
    //             strsearch: id,
    //             strfromdate: this.state.strfromdate,
    //             strenddate: this.state.strenddate,
    //             companycode: this.state.companycode,
    //             userid: 'sa'
    //         }

    //         //alert("inbox context get page")

    //         let url = BaseApi + "/api/stock/Productlistbymodel_surek_page";

    // //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));

    //         await axios.post(url, { data: page })


    //             .then((response) => {

    //             //  alert("context report getpage productlistbybrand 3  ->" + JSON.stringify(response.data));

    //                 let xpagecount = parseInt(response.data[0].totalrecord / 20);
    //                 let ypage = response.data[0].totalrecord % 20;
    //                 if (ypage > 0) { xpagecount = xpagecount + 1 }

    //                 let obj = this

    //                 setTimeout(function () {
    //                     obj.setState({
    //                         ...obj.state,
    //                         itempage: response.data,
    //                         totalrecord: response.data[0].totalrecord,
    //                         pagecount: xpagecount
    //                     });
    //                 }, 100)


    //                 setTimeout(function () {
    //                     callBack(response.data)
    //                 }, 100)


    //             })


    //     } catch (error) {
    //         //AlertError("error->" + error.message);
    //         callBack("")
    //     }
    // };

    getImage = async (code, callBack) => {
        try {

            // alert("sssssqqqqqqq"  + code);
            //alert("service api ->" + ServiceApi)

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: code,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'ssa'
            }


            let url = BaseApi + "/api/stock/Productdetail_image_surek"
            
        

            await axios.post(url, { data: page })
                .then((response) => {

                // AlertError("context from server  getdata ->" + JSON.stringify(response.data));


                let obj = this

                // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        // totalrecord: response.data[0].totalrecord,
                        // pagecount: xpagecount
                    });
                // }, 100)


                // setTimeout(function () {
                    callBack(response.data)
                // }, 100)

                // this.setState({
                //     ...this.state,
                //     itempage: response.data,
                // });
                // setTimeout(function () {
                //     callBack(response.data)
                // }, 500)
 
                

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getAll = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/dealer/dealer_user_all", { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //alert("user context save -> " + ServiceApi)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }
 


    

    getSummary = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stock/productcurrentstock_page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //   alert("context report summary by month- getSummary  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };



    
    Myresize = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/stock/Productdetail_resizeimage_surek";

            let page = {
              
                register: xdata,
                
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }




    render() {
        return (
            <Productdetailbyregister_SurekContext.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    getImage: this.getImage,
                    deleteData: this.deleteData,
                    Myresize:this.Myresize,
                    getPage: this.getPage,
                    getSummary: this.getSummary, 
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getAll: this.getAll,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu,
                    getMenubyUserid: this.getMenubyUserid,
                    saveAuthorize: this.saveAuthorize
                }}
            >
                {this.props.children}
            </Productdetailbyregister_SurekContext.Provider>
            
        );
    }
}
const Productdetailbyregister_SurekConsumer = Productdetailbyregister_SurekContext.Consumer;

export { Productdetailbyregister_SurekProvider, Productdetailbyregister_SurekConsumer, Productdetailbyregister_SurekContext };

export function withProductdetailbyregister_SurekConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Productdetailbyregister_SurekConsumer>
                {value => <Component {...props} context={value} />}
            </Productdetailbyregister_SurekConsumer>
        );
    };
}

