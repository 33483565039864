import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
 
import {
 
    AlertError,
} from '../../components/Utility/MyString';

const Acard_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";





export default class Acard_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: { 
                item_no:'',
                acard_no:'',
                jobdate:'',
                jobtime :'',
                saleperson:'',
                saleteam:'',
                sourceofcus:'',
                customername:'',
                address_no:'',
                address_road:'',
                address_tumbon:'',
                address_amphur:'',
                address_province:'',
                address_postcode:'',
                telephone:'',
                mobilephone:'',
                ext_lineid:'',
                ext_fbid:'',
                ext_email:'',
                ext_car_brand:'',
                ext_car_model:'',
                ext_car_year:'',
                note:'',
                jobstatus:'',
                inputtype:'',
                persontype:'',
                personname_prx:'',
                personname_main:'',
                personname_sfx:'',
                details1:'',
                details2:'',
                details3:'',
                details4:'',
                details5:'',
                companycode:'' 
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    item_no:'',
                    acard_no:'',
                    jobdate:'',
                    jobtime :'',
                    saleperson:'',
                    saleteam:'',
                    sourceofcus:'',
                    customername:'',
                    address_no:'',
                    address_road:'',
                    address_tumbon:'',
                    address_amphur:'',
                    address_province:'',
                    address_postcode:'',
                    telephone:'',
                    mobilephone:'',
                    ext_lineid:'',
                    ext_fbid:'',
                    ext_email:'',
                    ext_car_brand:'',
                    ext_car_model:'',
                    ext_car_year:'',
                    note:'',
                    jobstatus:'',
                    inputtype:'',
                    persontype:'',
                    personname_prx:'',
                    personname_main:'',
                    personname_sfx:'',
                    details1:'',
                    details2:'',
                    details3:'',
                    details4:'',
                    details5:'',
                    companycode:'' ,
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            address_postcode:'',
            ext_car_brand:'',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    
    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)


            

            await axios.get(BaseApi + "/api/sales/acard_getbyid", { params: { code: code } })
                .then((response) => {

                // AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getAll = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/dealer/dealer_user_all", { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("acard context save -> " + xdata)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getSummary(callBack)
        }, 200)

    }
 


    

    getSummary = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/report/summarybymonth_Ngc__page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //   alert("context report summary by month- getSummary  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    //function
    componentDidMount() {
        //this.getData();    
    }

    getRunning = async (code,callBack) => {
        try {
    
          //alert("product context getpage -> " + this.state.strsearch);

          let code = "101"
    
          let page = {
            code : code 
            
          }
    
          //alert("inbox context get page")
          let url = BaseApi + "/api/sales/acard_genid";
   
    
        // alert("context getpage ->" + JSON.stringify(page));
           
          await axios.post(url, { data : page })
            .then((response)=>{
    
            //   alert("context get running ->" + JSON.stringify(response.data));
      
              this.setState({
                ...this.state,
                data : {
                    item_no : response.data[0].item_no, 
                    acard_no : response.data[0].acard_no, 
                    jobdate : response.data[0].jobdate, 
                    jobtime : response.data[0].jobtime, 
                   
                },
                dataitem : [],
                datadealer : []
            }) 
    
            let xobj = this
            setTimeout(function(){
              callBack(xobj.state.data)
            },100)
                     
            })
          
          
        } catch (error) {
          //AlertError("error->" + error.message);
          callBack("")
        }
      };
    
      getAcardInfo = async (callBack) => {
        try {

 
 
            await axios.post(BaseApi + "/api/sales/acard_info", { params:  { userid: "" } })
                .then((response) => {

        // alert("context event get getAcardlist from server->" +  response.data.code);


                
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                        let obj = this

                        setTimeout(function () {
                            obj.setState({
                                ...obj.state,
                                itempage: response.data 
                          
                            });
                        }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getAcardInfo_des = async (callBack) => {
        try {

            let acard_info_id = sessionStorage.getItem("acard_info_id")

            let page = {
                code : acard_info_id 
                
              }


 
            await axios.post(BaseApi + "/api/sales/acard_info_des", {   data : page })
                .then((response) => {

        // alert("context event get getAcardlist from server->" +  response.data.code);
  
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                        let obj = this

                        setTimeout(function () {
                            obj.setState({
                                ...obj.state,
                                itempage: response.data 
                         
                           
                            });
                        }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getprovince = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : "" 
                 
               }
               


            await axios.post(BaseApi + "/api/sales/getprovince", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getDistrict = async (callBack) => {
        try {

            let ProvinceThai = sessionStorage.getItem("ProvinceThai")

            let page = {
                code : ProvinceThai 
                
              }
 
            await axios.post(BaseApi + "/api/sales/getdistrict", {   data : page })
                .then((response) => {

        // alert("context event get getAcardlist from server->" + JSON.stringify(response.data));
  
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data 
                            
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getTambon = async (callBack) => {
        try {

            let DistrictThai = sessionStorage.getItem("DistrictThai")

            let page = {
                code : DistrictThai 
                
              }
 
            await axios.post(BaseApi + "/api/sales/gettambon", {   data : page })
                .then((response) => {

         
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data  
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getPostcode = async (callBack) => {
        try {

            let tumbonid = sessionStorage.getItem("TambonID")
            let districtthai = sessionStorage.getItem("DistrictThai")

            let page = {
                code : tumbonid 
                ,districtthai :districtthai
                
              }
 
            await axios.post(BaseApi + "/api/sales/getpostcode", {   data : page })
                .then((response) => {

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getbrand = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : ""  
               }


            await axios.post(BaseApi + "/api/sales/getbrand", {   data : page })
                .then((response) => {

                // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getmodelname = async (callBack) => {
        try {

            let brand = sessionStorage.getItem("brand")
 
             let page = {
                 code : brand 
                 
               }


            await axios.post(BaseApi + "/api/sales/getmodelname", {   data : page })
                .then((response) => {

                // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getListpod = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

          

            let url = BaseApi + "/api/sales/acard_listpod";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    
    getListsou = async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_listsou";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getListmch = async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_listmch";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

            //    alert("context acard   mch  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getListocp= async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_listocp";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    render() {
        return (
            <Acard_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    getListpod: this.getListpod,
                    getListsou:this.getListsou,
                    getListmch:this.getListmch,
                    getListocp:this.getListocp,
                    getAcardInfo: this.getAcardInfo,
                    getAcardInfo_des: this.getAcardInfo_des,
                    getRunning: this.getRunning,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    getSummary: this.getSummary, 
                    getprovince:this.getprovince,
                    getDistrict: this.getDistrict,
                    getTambon:this.getTambon,
                    getPostcode:this.getPostcode,
                    getbrand:this.getbrand,
                    getmodelname:this.getmodelname,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getAll: this.getAll,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu, 
                    saveAuthorize: this.saveAuthorize
                }}
            >
                {this.props.children}
            </Acard_Context.Provider>
            
        );
    }
}
const Acard_Consumer = Acard_Context.Consumer;

export { Acard_Provider, Acard_Consumer, Acard_Context };

export function withAcard_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Acard_Consumer>
                {value => <Component {...props} context={value} />}
            </Acard_Consumer>
        );
    };
}

