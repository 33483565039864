import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
 
import {
 
    AlertError,
} from '../../components/Utility/MyString';

const DB_Context = React.createContext();
 

const ServiceApi = BaseApi + "/api/sales/db_save";





export default class DB_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: { 
                group1_id:'',
                group1_name3 :'',
                group1_name1:'',
                item_no:'',
                item_id:'',
                item_name1:'',
                input_caption:'',
                cusdb_id:'',
                jobdate:'',
                jobtime :'',
                saleperson:'',
                saleteam:'',
                sourceofcus:'',
                customername:'',
                address_no:'',
                address_road:'',
                address_tumbon:'',
                address_amphur:'',
                address_province:'',
                address_postcode:'',
                telephone:'',
                mobilephone:'',
                ext_lineid:'',
                ext_fbid:'',
                ext_email:'',
                ext_car_brand:'',
                ext_car_model:'',
                ext_car_year:'',
                note:'',
                jobstatus:'',
                inputtype:'',
                persontype:'',
                personname_prx:'',
                personname_main:'',
                personname_sfx:'',
                details1:'',
                details2:'',
                details3:'',
                details4:'',
                details5:'',
                ischecked : 0, 
                product:'',
                companycode:'' 
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    group1_id:'',
                    group1_name3 :'',
                    group1_name1:'',
                    group2_id:'',
                    group2name1 :'',
                    item_no:'',
                    input_caption:'',
                    DB_no:'', 
                    jobdate:'',
                    jobtime :'',
                    saleperson:'',
                    saleteam:'',
                    sourceofcus:'',
                    customername:'',
                    address_no:'',
                    address_road:'',
                    address_tumbon:'',
                    address_amphur:'',
                    address_province:'',
                    address_postcode:'',
                    telephone:'',
                    mobilephone:'',
                    ext_lineid:'',
                    ext_fbid:'',
                    ext_email:'',
                    ext_car_brand:'',
                    ext_car_model:'',
                    ext_car_year:'',
                    note:'',
                    jobstatus:'',
                    inputtype:'',
                    persontype:'',
                    personname_prx:'',
                    personname_main:'',
                    personname_sfx:'',
                    details1:'',
                    details2:'',
                    details3:'',
                    details4:'',
                    details5:'',
                    ischecked:0,
                    companycode:'' , 
                    count_all: 0,
                    count_db: 0,
                    count_acard: 0,
                    count_booking:0,
                    input1 : '',
                    input2 : '',
                    input3 : '',
                    input4 : '',
                    input5 : '',
                    input6 : '',
                    input7 : '',
                    input8 : '',
                    input9 : '',
                    input10 : '',
                    totalrecord: 0
                }],
                itempage2: [
                    {
                        group1_id:'',
                        group1_name3 :'',
                        group2_id:'',
                        group2name1 :'',
                        item_no:'',
                        input_caption:'',
                        DB_no:'', 
                        jobdate:'',
                        jobtime :'',
                        saleperson:'',
                        saleteam:'',
                        sourceofcus:'',
                        customername:'',
                        address_no:'',
                        address_road:'',
                        address_tumbon:'',
                        address_amphur:'',
                        address_province:'',
                        address_postcode:'',
                        telephone:'',
                        mobilephone:'',
                        ext_lineid:'',
                        ext_fbid:'',
                        ext_email:'',
                        ext_car_brand:'',
                        ext_car_model:'',
                        ext_car_year:'',
                        note:'',
                        jobstatus:'',
                        inputtype:'',
                        persontype:'',
                        personname_prx:'',
                        personname_main:'',
                        personname_sfx:'',
                        details1:'',
                        details2:'',
                        details3:'',
                        details4:'',
                        details5:'',
                        ischecked:0,
                        companycode:'' , 
                        count_all: 0,
                        count_db: 0,
                        count_acard: 0,
                        count_booking:0,
                        totalrecord: 1
                    }],
                    itempage_select: [
                        {
                            group1_id:'',
                            group1_name3 :'',
                            group2_id:'',
                            group2name1 :'',
                            item_no:'',
                            input_caption:'',
                            DB_no:'', 
                            jobdate:'',
                            jobtime :'',
                            saleperson:'',
                            saleteam:'',
                            sourceofcus:'',
                            customername:'',
                            address_no:'',
                            address_road:'',
                            address_tumbon:'',
                            address_amphur:'',
                            address_province:'',
                            address_postcode:'',
                            telephone:'',
                            mobilephone:'',
                            ext_lineid:'',
                            ext_fbid:'',
                            ext_email:'',
                            ext_car_brand:'',
                            ext_car_model:'',
                            ext_car_year:'',
                            note:'',
                            jobstatus:'',
                            inputtype:'',
                            persontype:'',
                            personname_prx:'',
                            personname_main:'',
                            personname_sfx:'',
                            details1:'',
                            details2:'',
                            details3:'',
                            details4:'',
                            details5:'',
                            ischecked:0,
                            companycode:'' , 
                            count_all: 0,
                            count_db: 0,
                            count_acard: 0,
                            count_booking:0,
                            totalrecord: 1
                        }], 
                        itempage_sourceofcus: [
                            {
                                group1_id:'',
                                group1_name3 :'',
                                group2_id:'',
                                group2name1 :'',
                                item_no:'',
                                input_caption:'',
                                DB_no:'', 
                                jobdate:'',
                                jobtime :'',
                                saleperson:'',
                                saleteam:'',
                                sourceofcus:'',
                                customername:'',
                                address_no:'',
                                address_road:'',
                                address_tumbon:'',
                                address_amphur:'',
                                address_province:'',
                                address_postcode:'',
                                telephone:'',
                                mobilephone:'',
                                ext_lineid:'',
                                ext_fbid:'',
                                ext_email:'',
                                ext_car_brand:'',
                                ext_car_model:'',
                                ext_car_year:'',
                                note:'',
                                jobstatus:'',
                                inputtype:'',
                                persontype:'',
                                personname_prx:'',
                                personname_main:'',
                                personname_sfx:'',
                                details1:'',
                                details2:'',
                                details3:'',
                                details4:'',
                                details5:'',
                                ischecked:0,
                                companycode:'' , 
                                count_all: 0,
                                count_db: 0,
                                count_acard: 0,
                                count_booking:0,
                                totalrecord: 1
                            }],
                            
                            itempage_modelname: [
                                {
                                    group1_id:'',
                                    group1_name3 :'',
                                    group2_id:'',
                                    group2name1 :'',
                                    item_no:'',
                                    input_caption:'',
                                    DB_no:'', 
                                    jobdate:'',
                                    jobtime :'',
                                    saleperson:'',
                                    saleteam:'',
                                    sourceofcus:'',
                                    customername:'',
                                    address_no:'',
                                    address_road:'',
                                    address_tumbon:'',
                                    address_amphur:'',
                                    address_province:'',
                                    address_postcode:'',
                                    telephone:'',
                                    mobilephone:'',
                                    ext_lineid:'',
                                    ext_fbid:'',
                                    ext_email:'',
                                    ext_car_brand:'',
                                    ext_car_model:'',
                                    ext_car_year:'',
                                    note:'',
                                    jobstatus:'',
                                    inputtype:'',
                                    persontype:'',
                                    personname_prx:'',
                                    personname_main:'',
                                    personname_sfx:'',
                                    details1:'',
                                    details2:'',
                                    details3:'',
                                    details4:'',
                                    details5:'',
                                    ischecked:0,
                                    companycode:'' , 
                                    count_all: 0,
                                    count_db: 0,
                                    count_acard: 0,
                                    count_booking:0,
                                    totalrecord: 1
                                }], 
                                itempage_modelspec: [
                                    {
                                        group1_id:'',
                                        group1_name3 :'',
                                        group2_id:'',
                                        group2name1 :'',
                                        item_no:'',
                                        input_caption:'',
                                        DB_no:'', 
                                        jobdate:'',
                                        jobtime :'',
                                        saleperson:'',
                                        saleteam:'',
                                        sourceofcus:'',
                                        customername:'',
                                        address_no:'',
                                        address_road:'',
                                        address_tumbon:'',
                                        address_amphur:'',
                                        address_province:'',
                                        address_postcode:'',
                                        telephone:'',
                                        mobilephone:'',
                                        ext_lineid:'',
                                        ext_fbid:'',
                                        ext_email:'',
                                        ext_car_brand:'',
                                        ext_car_model:'',
                                        ext_car_year:'',
                                        note:'',
                                        jobstatus:'',
                                        inputtype:'',
                                        persontype:'',
                                        personname_prx:'',
                                        personname_main:'',
                                        personname_sfx:'',
                                        details1:'',
                                        details2:'',
                                        details3:'',
                                        details4:'',
                                        details5:'',
                                        ischecked:0,
                                        companycode:'' , 
                                        product:'',
                                        count_all: 0,
                                        count_db: 0,
                                        count_acard: 0,
                                        count_booking:0,
                                        totalrecord: 1
                                    }], 
                                    itempage_gettitle: [
                                        {
                                            item_id:'',
                                            item_name1 :'', 
                                            totalrecord: 1
                                    }],
                                    itempage_getsourceofcus: [
                                        {
                                            item_id:'',
                                            item_name1 :'', 
                                            totalrecord: 1
                                    }],



                                        

            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            address_postcode:'',
            ext_car_brand:'',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            count_all: 0,
            count_db: 0,
            count_acard: 0,
            count_booking:0,
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {
            let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }  

            let url = BaseApi + "/api/sales/db_page";
 
            await axios.post(url, { data: page }) 

                .then((response) => {
 
                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            count_all: response.data[0].count_all,
                            count_db: response.data[0].count_db,
                            count_acard: response.data[0].count_acard,
                            count_acard_hot: response.data[0].count_acard_hot,
                            count_acard_warm: response.data[0].count_acard_warm,
                            count_acard_cold: response.data[0].count_acard_cold,
                            count_booking: response.data[0].count_booking,
                            pagecount: xpagecount
                        });
                    }, 100)
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200) 

                }) 

        } catch (error) {
            // AlertError("error :->" + error.message);
            callBack([{error:error.message}])
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    
    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)


            

            await axios.get(BaseApi + "/api/sales/DB_getbyid", { params: { code: code } })
                .then((response) => {

                // AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("DB context save -> " + xdata)

        let cusdb_id = sessionStorage.getItem("cusdb_id")
        let  item_id = sessionStorage.getItem("item_id")
        let details_item_id = sessionStorage.getItem("details_item_id")
        let input01 = sessionStorage.getItem("input01")
        let score = sessionStorage.getItem("score")
        let companycode = sessionStorage.getItem("companycode")
        let userid = sessionStorage.getItem("userid")

        let xdata = {
            cusdb_id : cusdb_id,
            item_id : item_id ,
            details_item_id : details_item_id,
            input01 : input01,
            score: score,
            companycode : companycode,
            userid : userid
          }
      


        let url = BaseApi + "/api/sales/db_save";

        //   alert("context getpage report  summary by sales->" + JSON.stringify(page));
 
             await axios.post(url, { data: xdata }) 
         
                .then((res) => {

                    //  AlertError("context save callback > " + JSON.stringify(res.data))
                    
               
                        if (res.data === "true") {
                            callBack(true)
                        }
                 
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {

                    // AlertError("context save " + res.data)

                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, callBack) => {

  

        this.setState({
 
            ...this.state,
         
            strsearch:str1.strsearch,
            pageindex: 1

        });
        let obj = this
        setTimeout(function () {
         
            obj.getPage(callBack)
        }, 100)

    }


    

    getSummary = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/report/summarybymonth_Ngc__page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //   alert("context report summary by month- getSummary  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    //function
    componentDidMount() {
        //this.getData();    
    }

    getRunning = async (code,callBack) => {
        try {
    
          //alert("product context getpage -> " + this.state.strsearch);

          let code = "CDB"
    
          let page = {
            code : code 
            
          }
    
          //alert("inbox context get page")
          let url = BaseApi + "/api/sales/db_genid";
   
    
        //  alert("context getpage ->" + JSON.stringify(page));
           
          await axios.post(url, { data : page })
            .then((response)=>{
    
            //   alert("context get running ->" + JSON.stringify(response.data));
      
              this.setState({
                ...this.state,
                data : {
                    item_no : response.data[0].item_no, 
                    cusdb_id : response.data[0].cusdb_id, 
                    jobdate : response.data[0].jobdate, 
                    jobtime : response.data[0].jobtime, 
                   
                },
                dataitem : [],
                datadealer : []
            }) 
    
            let xobj = this
            setTimeout(function(){
              callBack(xobj.state.data)
            },100)
                     
            })
          
          
        } catch (error) {
          //AlertError("error->" + error.message);
          callBack("")
        }
      };
    
      getDBInfo = async (callBack) => {
        try {

 
 
            await axios.post(BaseApi + "/api/sales/DB_info", { params:  { userid: "" } })
                .then((response) => {

        // alert("context event get getDBlist from server->" +  response.data.code);


                
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                        let obj = this

                        setTimeout(function () {
                            obj.setState({
                                ...obj.state,
                                itempage: response.data 
                          
                            });
                        }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getDBInfo_des = async (callBack) => {
        try {

            let DB_info_id = sessionStorage.getItem("DB_info_id")

            let page = {
                code : DB_info_id 
                
              }


 
            await axios.post(BaseApi + "/api/sales/DB_info_des", {   data : page })
                .then((response) => {

        alert("context event get getDBlist from server->" +  response.data.code);
  
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                        let obj = this

                        setTimeout(function () {
                            obj.setState({
                                ...obj.state,
                                itempage: response.data 
                         
                           
                            });
                        }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getprovince = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : "" 
                 
               }
               


            await axios.post(BaseApi + "/api/sales/getprovince", {   data : page })
                .then((response) => {

                // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getAmphur = async (callBack) => {
        try {

            let provinceid = sessionStorage.getItem("provinceid")

            let page = {
                code : provinceid 
                
              }
 
            await axios.post(BaseApi + "/api/sales/getamphur", {   data : page })
                .then((response) => {

        // alert("context event get getDBlist from server->" + JSON.stringify(response.data));
  
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data 
                            
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getTumbon = async (callBack) => {
        try {

            let amphurid = sessionStorage.getItem("amphurid")

            let page = {
                code : amphurid 
                
              }
 
            await axios.post(BaseApi + "/api/sales/gettumbon", {   data : page })
                .then((response) => {

         
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data  
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getDistrict_code = async (callBack) => {
        try {

            let tumbonid = sessionStorage.getItem("tumbonid")

            let page = {
                code : tumbonid 
                
              }
 
            await axios.post(BaseApi + "/api/sales/getdistrict_code", {   data : page })
                .then((response) => {

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getbrand = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : ""  
               }


            await axios.post(BaseApi + "/api/sales/getbrand", {   data : page })
                .then((response) => {

                // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getmodelname = async (callBack) => {
        try {

            let brand = sessionStorage.getItem("brand")
 
             let page = {
                 code : brand 
                 
               }


            await axios.post(BaseApi + "/api/sales/getmodelname", {   data : page })
                .then((response) => {

                // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getListpod = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

          

            let url = BaseApi + "/api/sales/DB_listpod";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    
    getListsou = async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/DB_listsou";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getListmch = async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/DB_listmch";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

            //    alert("context DB   mch  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getListocp= async (callBack) => {
        try {

       

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch, 
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/DB_listocp";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    getG01_04 = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : ""  
               }


            await axios.post(BaseApi + "/api/sales/getg01_04", {   data : page })
                .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    getG01_03 = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : ""  
               }


            await axios.post(BaseApi + "/api/sales/getg01_03", {   data : page })
                .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    
    getDb_Gup1 = async (code,callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                cusdb_id : sessionStorage.getItem("cusdb_id"),
                group1_id : sessionStorage.getItem("group1_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")  
               }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_gup1", {   data : page })
                .then((response) => {

        //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data));
                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100) 
              
                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)
 
                }) 

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

     
    getDb_Gup2 = async (callBack) => {
        try {

      
            let page = {
              
                cusdb_id : sessionStorage.getItem("cusdb_id"),
                group1_id : sessionStorage.getItem("group1_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")

              }


            await axios.post(BaseApi + "/api/sales/getdb_gup2", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
    
  
    getDb_ITM = async (callBack) => {
        try {

      
            let page = {
                cusdb_id : sessionStorage.getItem("cusdb_id"),
                group2_id : sessionStorage.getItem("group2_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")

              }


            await axios.post(BaseApi + "/api/sales/getdb_itm", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    
    getDb_ItemDetail = async (callBack) => {
        try {

      
            let page = {
                item_id : sessionStorage.getItem("item_id")

              }


            await axios.post(BaseApi + "/api/sales/getdb_itemdetail", {   data : page })
                .then((response) => {

    //   alert("context getDb_ItemDetail  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
 
    getDb_ITEM_ID = async (callBack) => {
        try {

      
            let page = {
                group2_id : sessionStorage.getItem("group2_id")

              }


            await axios.post(BaseApi + "/api/sales/getdb_item_id", {   data : page })
                .then((response) => {

    //   alert("context getDb_ItemDetail  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getDb_ItemSelect = async (callBack) => {
        try {

      
            let page = {
                item_id : sessionStorage.getItem("item_id"), 
                input_table1 :sessionStorage.getItem("input_table1")

              }


    
       

            await axios.post(BaseApi + "/api/sales/getdb_itemselect", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage_select : response.data  
                      });
                      },100)

                      setTimeout(function () {
                        callBack(response.data)
                    }, 200)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getDB_ITM_ListData = async (callBack) => {
        try {

      
            let page = {
         
                details_item_id : sessionStorage.getItem("details_item_id"),
                input_table1:  sessionStorage.getItem("input_table1")

              }


            await axios.post(BaseApi + "/api/sales/getdb_itm_listdata", {   data : page })
                .then((response) => {

    //   alert("context getDb_ItemDetail  from server->" + JSON.stringify(response.data));



                    // this.setState({
                    //       ...this.state,
                    //     data: response.data,
                    // });
                    setTimeout(function () {

                        // AlertError("From context getDB_ITM_ListData ->" + JSON.stringify(response.data));
                        callBack(response.data)
                    }, 100)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
    
    getDb_Mutitext = async (code,callBack) => {
        try {

      
            let page = {
                item_id : sessionStorage.getItem("item_id"), 
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")
              }
 
            await axios.post(BaseApi + "/api/sales/getdb_mutitext", {   data : page })
                .then((response) => {

                    // let obj = this
                    // alert("context getDb_Mutitext  from server->" + JSON.stringify(response.data));
 




                    this.setState({
                        ...this.state,

                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)



                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    

    saveDataMutitext = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("DB context save -> " + xdata)

        let cusdb_id = sessionStorage.getItem("cusdb_id")
        let item_id = sessionStorage.getItem("item_id")
        let caption_id = sessionStorage.getItem("caption_id")
        let input01 = sessionStorage.getItem("input01")
        let input02 = sessionStorage.getItem("input02")
        let input03 = sessionStorage.getItem("input03")
        let input04 = sessionStorage.getItem("input04")
        let input05 = sessionStorage.getItem("input05")
        let input06 = sessionStorage.getItem("input06")
        let input07 = sessionStorage.getItem("input07")  
        let input08 = sessionStorage.getItem("input08")
        let input09 = sessionStorage.getItem("input09")
        let input10 = sessionStorage.getItem("input10")
        let companycode = sessionStorage.getItem("companycode")
        let userid = sessionStorage.getItem("userid")

        let xdata = {
            cusdb_id : cusdb_id,
            item_id : item_id ,
            caption_id : caption_id,
            input01 : input01,
            input02 : input02,
            input03 : input03,
            input04 : input04,
            input05 : input05,
            input06 : input06,
            input07 : input07,
            input08 : input08,
            input09 : input09,
            input10 : input10,
            companycode : companycode,
            userid : userid
          }
      


        let url = BaseApi + "/api/sales/db_savemutitext";

        //   alert("context getpage report  summary by sales->" + JSON.stringify(page));
 
             await axios.post(url, { data: xdata }) 
         
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getCus_DB = async (callBack) => {
        try {

      
            let page = {
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")          

              }


    
       

            await axios.post(BaseApi + "/api/sales/getdb_cusdb", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    getDb_Input_type = async (callBack) => {
        try {

      
            let page = {
                item_id : sessionStorage.getItem("item_id")

              }


            await axios.post(BaseApi + "/api/sales/getdb_item_id", {   data : page })
                .then((response) => {

    //   alert("context getDb_ItemDetail  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    getData_Db_Mutitext = async (code,callBack) => {
        try {

      
            let page = {
                cusdb_id : sessionStorage.getItem("cusdb_id"), 
                item_id : sessionStorage.getItem("item_id"), 
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")
              }
 
            await axios.post(BaseApi + "/api/sales/getdata_db_mutitext", {   data : page })
                .then((response) => {

                    // let obj = this
                    // alert("context getDb_Mutitext  from server->" + JSON.stringify(response.data));
 
                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
    


    
    saveMutiSelect = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("DB context save -> " + xdata)

        let cusdb_id = sessionStorage.getItem("cusdb_id")
        let  item_id = sessionStorage.getItem("item_id")
        let details_item_id = sessionStorage.getItem("details_item_id")
        let input01 = sessionStorage.getItem("input01")
        let companycode = sessionStorage.getItem("companycode")
        let userid = sessionStorage.getItem("userid")

        let xdata = {
            cusdb_id : cusdb_id,
            item_id : item_id ,
            details_item_id : details_item_id,
            input01 : input01,
            companycode : companycode,
            userid : userid
          }
      


        let url = BaseApi + "/api/sales/db_savemutiselect";

        //   alert("context getpage report  summary by sales->" + JSON.stringify(page));
 
             await axios.post(url, { data: xdata }) 
         
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    

    
    getDb_ItemMutiSelect = async (callBack) => {
        try {

      
            let page = {
                 cusdb_id : sessionStorage.getItem("cusdb_id")
                ,item_id : sessionStorage.getItem("item_id")  
                ,companycode : sessionStorage.getItem("companycode")
                ,userid : sessionStorage.getItem("userid")
 
              }

 
            await axios.post(BaseApi + "/api/sales/getdb_itemmutiselect", {   data : page })
                .then((response) => {

                    // let obj = this
 
                    // setTimeout(function(){
                    //     obj.setState({
                    //       ...obj.state,               
                    //       itempage : response.data  
                    //   });
                    //   },500)

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                      });

                      setTimeout(function(){
                        callBack(response.data)
                    },500)
                    

                    //   this.setState({
                    //     ...this.state,               
                    //     itempage : response.data             
                    // });
                    // setTimeout(function(){
                    //     callBack(response.data)
                    // },500)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    
     
    
    gettitle = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : "" 
                 
               }
               


            await axios.post(BaseApi + "/api/sales/gettitle", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                  
                    this.setState({
                        ...this.state,               
                        itempage_gettitle : response.data,              
                    });
                   
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

     
    getsourceofcus = async (callBack) => {
        try {

            //  alert("getprovince");
  
             let page = {
                 companycode : sessionStorage.getItem("companycode")
                ,userid : sessionStorage.getItem("userid")
                 
               }
               


            await axios.post(BaseApi + "/api/sales/getsourceofcus", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                  
                    this.setState({
                        ...this.state,               
                        itempage_getsourceofcus : response.data,              
                    });
                  
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    

    saveDataNewAcard = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("DB context save -> " + xdata)

        let cusdb_id = sessionStorage.getItem("cusdb_id")
        let item_id = sessionStorage.getItem("item_id")
        let caption_id = sessionStorage.getItem("caption_id")
        let input01 = sessionStorage.getItem("input01")
        let input02 = sessionStorage.getItem("input02")
        let input03 = sessionStorage.getItem("input03")
        let input04 = sessionStorage.getItem("input04")
        let input05 = sessionStorage.getItem("input05")
        let input06 = sessionStorage.getItem("input06") 
        let input07 = sessionStorage.getItem("input07") 
        let input08 = sessionStorage.getItem("input08") 
        let inputjobs = sessionStorage.getItem("inputjobs") 
        let companycode = sessionStorage.getItem("companycode")
        let userid = sessionStorage.getItem("userid")

        let xdata = {
            cusdb_id : cusdb_id,
            item_id : item_id ,
            caption_id : caption_id,
            input01 : input01,
            input02 : input02,
            input03 : input03,
            input04 : input04,
            input05 : input05,
            input06 : input06,  
            input07 : input07, 
            input08 : input08, 
            inputjobs: inputjobs,
            companycode : companycode,
            userid : userid
          }
      


        let url = BaseApi + "/api/sales/db_savedatanewacard";

        //   alert("context getpage report  summary by sales->" + JSON.stringify(page));
 
             await axios.post(url, { data: xdata }) 
         
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

 

    sentToAcard = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("DB context save -> " + xdata)

        let cusdb_id = sessionStorage.getItem("cusdb_id") 
        let companycode = sessionStorage.getItem("companycode")
        let userid = sessionStorage.getItem("userid")

        let xdata = {
            cusdb_id : cusdb_id, 
            companycode : companycode,
            userid : userid
          }
      


        let url = BaseApi + "/api/sales/db_senttoacard";

        //   alert("context getpage report  summary by sales->" + JSON.stringify(page));
 
             await axios.post(url, { data: xdata }) 
         
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    


    getDb_G07 = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                cusdb_id : sessionStorage.getItem("cusdb_id"),
                group1_id : sessionStorage.getItem("group1_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")  
               }

            //  alert("context getDb_G07 from context->" + JSON.stringify(page));



            await axios.post(BaseApi + "/api/sales/getdb_g07", {   data : page })
                .then((response) => {

      
                    // let obj = this
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    
    getDb_ItemModel = async (code,callBack) => {
        try {

      
            let page = {
                cusdb_id : sessionStorage.getItem("cusdb_id"),
                item_modelname :sessionStorage.getItem("item_modelname"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")   
              }


    
       

            await axios.post(BaseApi + "/api/sales/getdb_modelspec", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage_modelspec : response.data  
                      });
                      },100)

                      setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    
    getDb_ItemSelectModelname = async (callBack) => {
        try {

      
            let page = {
                item_id : sessionStorage.getItem("item_id"), 
                input_table1 :sessionStorage.getItem("input_table1")  

              }


    
       

            await axios.post(BaseApi + "/api/sales/getdb_modelname", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage_modelname : response.data  
                      });
                      },100)

                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    
    getjobs = async (callBack) => {

        try { 
             let page = {
                userid :sessionStorage.getItem("userid")  
                 
               }
                

            await axios.post(BaseApi + "/api/sales/getjobs", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                   
                    this.setState({
                        ...this.state,               
                        itempage_job : response.data,              
                    });
                   
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

     
    
    getAcardlistbyteam = async (callBack) => {
        try {

      
            let page = {
                team_id : sessionStorage.getItem("team_id"), 
                job_id :sessionStorage.getItem("job_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")   

              }
 

            await axios.post(BaseApi + "/api/sales/getacardlistbyteam", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                      
                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

      
    
    genExportExcelbyTeam = async (callBack) => {
        try {

      
            let page = {
                team_id : sessionStorage.getItem("team_id"), 
                job_id :sessionStorage.getItem("job_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")   

              }
 

            await axios.post(BaseApi + "/api/sales/genexportexcelbyteam", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

     
    QMS_CUSDB_GROUPBY_TEAM = async (callBack) => {
        try {

      
            let page = {
                team_id : sessionStorage.getItem("team_id"), 
                job_id :sessionStorage.getItem("job_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")   

              }
 

            await axios.post(BaseApi + "/api/sales/qms_cusdb_groupby_team", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage : response.data  
                      });
                      },100)
                      
                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    

      
    QMS_CUSDB_GROUPBY_SALES = async (callBack) => {
        try {

      
            let page = {
                team_id : sessionStorage.getItem("team_id"), 
                job_id :sessionStorage.getItem("job_id"),
                companycode :sessionStorage.getItem("companycode"),
                userid :sessionStorage.getItem("userid")   

              }
 

            await axios.post(BaseApi + "/api/sales/qms_cusdb_groupby_sales", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage2 : response.data  
                      });
                      },100)
                      
                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)


                })

            


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    


    
    
    getDb_ItemOccupation = async (callBack) => {
        try {
 
            let page = {
                parentcode : sessionStorage.getItem("parentcode"), 
                input_table1 :sessionStorage.getItem("input_table1")  

              }
 

            await axios.post(BaseApi + "/api/sales/getdb_occupation", {   data : page })
                .then((response) => {

                    let obj = this
 
                    setTimeout(function(){
                        obj.setState({
                          ...obj.state,               
                          itempage_sourceofcus : response.data  
                      });
                      },100)

                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)
 
                })
 

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
 

    getDb_ItemOccation_List = async (callBack) => {
        try {
 
            let page = {
                idno : sessionStorage.getItem("idno"), 
                input_table1 :sessionStorage.getItem("input_table1")  

              }
 

            await axios.post(BaseApi + "/api/sales/getdb_occupation_list", {   data : page })
                .then((response) => {

                    // let obj = this
 
                    // setTimeout(function(){
                    //     obj.setState({
                    //       ...obj.state,               
                    //       itempage : response.data  
                    //   });
                    //   },100)

                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)
 
                })
 

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

     

    getprospecting = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : "" 
                 
               }
               


            await axios.post(BaseApi + "/api/sales/getprospecting", {   data : page })
                .then((response) => {

            //   AlertError("context from server getprospecting  ->" + JSON.stringify(response.data));
                   
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                  
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    

    

    getDb_ItemOccation_2 = async (callBack) => {
        try {
 
            let page = {
                idno : sessionStorage.getItem("occupationtitle"), 
                input_table1 :sessionStorage.getItem("input_table1")  

              }
 
                // alert(page)

            await axios.post(BaseApi + "/api/sales/getdb_occupation_2", {   data : page })
                .then((response) => {

                    // let obj = this
 
                    // setTimeout(function(){
                    //     obj.setState({
                    //       ...obj.state,               
                    //       itempage : response.data  
                    //   });
                    //   },100)

                      setTimeout(function () {
                        callBack(response.data)
                      }, 100)
 
                })
 

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };
    

    
    getAll = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
       
            obj.getPage(callBack)
        }, 100)

    }
    
    
    
    getDBSummaryBysale = async (callBack) => {
        try {
            let xstrsearch = sessionStorage.getItem("strsearch")
            let strfromdate = sessionStorage.getItem("strfromdate")
            let strenddate = sessionStorage.getItem("strenddate")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strfromdate: strfromdate,
                strenddate: strenddate,
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }  

            let url = BaseApi + "/api/sales/db_summary_by_sales";
 
            await axios.post(url, { data: page }) 

                .then((response) => {
 
                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            // count_all: response.data[0].count_all,
                            // count_db: response.data[0].count_db,
                            // count_acard: response.data[0].count_acard,
                            // count_booking: response.data[0].count_booking,
                            pagecount: xpagecount
                        });
                    }, 100)
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200) 

                }) 

        } catch (error) {
            // AlertError("error :->" + error.message);
            callBack([{error:error.message}])
        }
    };

    
    previousPageSummary = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getDBSummaryBysale(callBack)
        }, 200)

    }

    nextPageSummary = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getDBSummaryBysale(callBack)
        }, 200)

    }

    
    getDBSummaryByJob = async (callBack) => {
        try {
            let xstrsearch = sessionStorage.getItem("strsearch")
            let strfromdate = sessionStorage.getItem("strfromdate")
            let strenddate = sessionStorage.getItem("strenddate")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strfromdate: strfromdate,
                strenddate: strenddate,
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }  

            let url = BaseApi + "/api/sales/db_summary_by_job";
 
            await axios.post(url, { data: page }) 

                .then((response) => {
 
                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            // count_all: response.data[0].count_all,
                            // count_db: response.data[0].count_db,
                            // count_acard: response.data[0].count_acard,
                            // count_booking: response.data[0].count_booking,
                            pagecount: xpagecount
                        });
                    }, 100)
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200) 

                }) 

        } catch (error) {
            // AlertError("error :->" + error.message);
            callBack([{error:error.message}])
        }
    };

    
    previousPageSummaryJob = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getDBSummaryBysale(callBack)
        }, 200)

    }

    nextPageSummaryJob = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getDBSummaryBysale(callBack)
        }, 200)

    }

    render() {
        return (
            <DB_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    saveDataMutitext :  this.saveDataMutitext,
                    getData: this.getData,
                    getListpod: this.getListpod,
                    getListsou:this.getListsou,
                    getListmch:this.getListmch,
                    getListocp:this.getListocp,
                    getDBInfo: this.getDBInfo,
                    getDBInfo_des: this.getDBInfo_des,
                    getRunning: this.getRunning,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    getSummary: this.getSummary, 
                    getprovince:this.getprovince,
                    getAmphur: this.getAmphur,
                    getTumbon:this.getTumbon,
                    getDistrict_code:this.getDistrict_code,
                    getbrand:this.getbrand, 
                    getmodelname:this.getmodelname,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getAll: this.getAll,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu, 
                    getDb_Gup1: this.getDb_Gup1,
                    getDb_Gup2: this.getDb_Gup2,
                    getDb_ITM : this.getDb_ITM,
                    getDb_Input_type : this.getDb_Input_type,
                    getData_Db_Mutitext : this.getData_Db_Mutitext, 
                    saveMutiSelect : this.saveMutiSelect,
                    getCus_DB: this.getCus_DB,
                    getDb_ITEM_ID : this.getDb_ITEM_ID,
                    getDb_ItemDetail: this.getDb_ItemDetail,
                    getDb_ItemModel: this.getDb_ItemModel,
                    getDb_ItemSelect : this.getDb_ItemSelect,
                    getDb_ItemSelectModelname: this.getDb_ItemSelectModelname,
                    getDb_ItemMutiSelect : this.getDb_ItemMutiSelect,
                    getDb_Mutitext: this.getDb_Mutitext,
                    getDB_ITM_ListData: this.getDB_ITM_ListData,
                    getG01_04: this.getG01_04,
                    getG01_03: this.getG01_03,
                    getDb_G07: this.getDb_G07,
                    gettitle: this.gettitle,
                    getjobs :  this.getjobs, 
                    sentToAcard: this.sentToAcard,
                    saveDataNewAcard:this.saveDataNewAcard,
                    getsourceofcus: this.getsourceofcus,
                    getprospecting: this.getprospecting,
                    getAcardlistbyteam:this.getAcardlistbyteam,
                    genExportExcelbyTeam:this.genExportExcelbyTeam, 
                    QMS_CUSDB_GROUPBY_TEAM: this.QMS_CUSDB_GROUPBY_TEAM,
                    QMS_CUSDB_GROUPBY_SALES: this.QMS_CUSDB_GROUPBY_SALES,
                    getDb_ItemOccupation: this.getDb_ItemOccupation,
                    getDb_ItemOccation_2:this.getDb_ItemOccation_2,
                    getDb_ItemOccation_List: this.getDb_ItemOccation_List, 
                    saveAuthorize: this.saveAuthorize,
                    getDBSummaryBysale:this.getDBSummaryBysale,
                    nextPageSummary: this.nextPageSummary,
                    previousPageSummary: this.previousPageSummary,
                    getDBSummaryByJob:this.getDBSummaryByJob,
                    nextPageSummaryJob: this.nextPageSummaryJob,
                    previousPageSummaryJob: this.previousPageSummaryJob
                }}
            >
                {this.props.children}
            </DB_Context.Provider>
            
        );
    }
}
const DB_Consumer = DB_Context.Consumer;

export { DB_Provider, DB_Consumer, DB_Context };

export function withDB_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <DB_Consumer>
                {value => <Component {...props} context={value} />}
            </DB_Consumer>
        );
    };
}

