import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'; 

// import { IonApp, IonRouterOutlet } from '@ionic/react';
// import { IonReactRouter } from '@ionic/react-router';

// import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
// import { calendar, personCircle, map, informationCircle } from 'ionicons/icons';

 

import { createBrowserHistory } from 'history'; // for remove # from URL
 

import { UserProvider } from './context/Admin/UserContext';
import { UserDealerProvider } from './context/UserDealerContext';

import { CompanyProfileProvider } from './context/Admin/CompanyProfileContext';
import { ProductProvider } from './context/ProductContext';
import { DealerProvider } from './context/DealerContext';
import { VendorProvider } from './context/VendorContext';
import { R01Provider } from './context/R01Context';

import { Summarybysales_Provider } from './context/Summarybysales_Context';
import { SummarybyteamProvider } from './context/SummarybyteamContext';


import { SummarybysalesSureprgProvider } from './context/Summarybysales_SureprgContext';
import { SummarybymonthSureprgProvider } from './context/Summarybymonth_SureprgContext';
import { SummarybyyearSureprgProvider } from './context/Summarybyyear_SureprgContext';

import { SummarybymonthParagonProvider } from './context/Summarybymonth_ParagonContext';
import { SummarybyyearParagonProvider } from './context/Summarybyyear_ParagonContext';

import { SummarybysalesSurekProvider } from './context/Summarybysales_SurekContext';
import { SummarybymonthSurekProvider } from './context/Summarybymonth_SurekContext';
import { SummarybyyearSurekProvider } from './context/Summarybyyear_SurekContext';


import { SummarybymonthNgcProvider } from './context/Summarybymonth_NgcContext';


import { Productcurrentstock_SurekProvider } from './context/Productcurrentstock_SurekContext';
import { Productlistbybrand_SurekProvider } from './context/Productlistbybrand_SurekContext';
import { Productlistbymodel_SurekProvider } from './context/Productlistbymodel_SurekContext';
import { ProductSearch_surekProvider } from './context/ProductSearch_surekContext';
import { Productdetailbyregister_SurekProvider } from './context/Productdetailbyregister_SurekContext';

import { Product_Sold_Provider } from './context/Product/Product_Sold_Context';
import { Productcurrentstock_Provider } from './context/Productcurrentstock_Context';


import { Productlistbranch_Provider } from './context/Productlistbranch_Context';
// import { ProductlistbybrandProvider } from './context/ProductlistbybrandContext';
import { Productlistbybrand_Provider } from './context/Productlistbybrand_Context';
import { Productlistbymodel_Provider } from './context/Productlistbymodel_Context';
import { Productdetailbyregister_Provider } from './context/Productdetailbyregister_Context'; 
import { ProductSearch_Provider } from './context/ProductSearch_Context'; 


import { Address_Provider } from './context/Address/Address_Context';
import { Acard_Provider } from './context/Sales/Acard_Context';
import { DB_Provider } from './context/Sales/DB_Context';
import { Job_Provider } from './context/Sales/Job_Context';
import { DB_RPT_Provider } from './context/Sales/DB_RPT_Context';
import { Filekeep_Provider } from './context/Filekeep/Filekeep_Context'; 
import { ShoppingCartProvider } from './context/ShoppingCartContext';


import './App.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
 

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Productdetailbyregister_ngcshow = React.lazy(() => import('./views/Stock/Productdetailbyregister_ngcshow'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

//for remove # from URL && use for BrowserRouter only
const browserHistory = createBrowserHistory()

class App extends Component {

  render() {
    return (
      <UserProvider>
        <CompanyProfileProvider>
          <UserDealerProvider>
          <Productcurrentstock_Provider>
          <Productlistbranch_Provider> 
          <Productlistbybrand_Provider>
          <Productlistbymodel_Provider>
          <Productdetailbyregister_Provider>
          <ProductSearch_Provider>
          <Product_Sold_Provider>

          <DB_Provider>
          <DB_RPT_Provider> 
            <Job_Provider>
          <Address_Provider>
          <Filekeep_Provider>

            <Summarybysales_Provider>
              <SummarybyteamProvider>
                <SummarybysalesSureprgProvider>
                  <SummarybymonthSureprgProvider>
                    <SummarybyyearSureprgProvider>
                      <SummarybysalesSurekProvider>
                        <SummarybymonthParagonProvider>
                          <SummarybyyearParagonProvider>
                            <SummarybymonthSurekProvider>
                              <SummarybyyearSurekProvider>
                                <Productcurrentstock_SurekProvider>
                                  <Productlistbybrand_SurekProvider>
                                    <Productdetailbyregister_SurekProvider>
                                      <ProductSearch_surekProvider>
                                        <Productlistbymodel_SurekProvider> 
                                         
                                             
                                             
                                                
                                                    
                                                    <SummarybymonthNgcProvider>

                                               
                                                  
                                                      
                                                    <Acard_Provider>
                                                   

                                                    <R01Provider>
                                                      <VendorProvider>
                                                        <ProductProvider>
                                                          <DealerProvider>
                                                            <ShoppingCartProvider>
                                                              <Router history={browserHistory}>
                                                                <React.Suspense fallback={loading()}>
                                                                  <Switch>
                                                                    <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                                                                    <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                                                                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                                                                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                                                                    <Route exact path="/stock/Productdetailbyregister_ngcshow/:id" name="Productdetailbyregister_ngcshow page" render={props => <Productdetailbyregister_ngcshow  {...props} />} />
                                                                    <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
                                                                  </Switch>
                                                                </React.Suspense>
                                                              </Router>
                                                            </ShoppingCartProvider>
                                                          </DealerProvider>
                                                        </ProductProvider>
                                                      </VendorProvider>
                                                    </R01Provider>

                                                    </Acard_Provider>
                                                   
                                                 
                                                    
                                                    </SummarybymonthNgcProvider>
                                                  
                                                
                                              
                                           
                                        
                                        </Productlistbymodel_SurekProvider>
                                      </ProductSearch_surekProvider>
                                    </Productdetailbyregister_SurekProvider>
                                  </Productlistbybrand_SurekProvider>
                                </Productcurrentstock_SurekProvider>
                              </SummarybyyearSurekProvider>
                            </SummarybymonthSurekProvider>
                          </SummarybyyearParagonProvider>
                        </SummarybymonthParagonProvider>
                      </SummarybysalesSurekProvider>
                    </SummarybyyearSureprgProvider>
                  </SummarybymonthSureprgProvider>
                </SummarybysalesSureprgProvider>
              </SummarybyteamProvider>
            </Summarybysales_Provider>

            </Filekeep_Provider>
            </Address_Provider>
            </Job_Provider>
            </DB_RPT_Provider>
            </DB_Provider>
            </Product_Sold_Provider>
            </ProductSearch_Provider>
            </Productdetailbyregister_Provider>
            </Productlistbymodel_Provider>
            </Productlistbybrand_Provider> 
            </Productlistbranch_Provider> 
            </Productcurrentstock_Provider>
          </UserDealerProvider>
        </CompanyProfileProvider>
      </UserProvider>

    );
  }
}

export default App;
