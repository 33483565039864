 

 
 

 const BaseApi = "https://www.quickmanagesoft.com"

// const BaseApi = "http://43.254.133.244" 
 

// const BaseApi = "http://127.0.0.1:5000" 

export default BaseApi;
 