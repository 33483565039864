import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
import { message } from 'antd'
import {
    AlertCompleSave,
    AlertError,
} from '../components/Utility/MyString';

const Productdetailbyregister_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/stock/Productdetail";





export default class Productdetailbyregister_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                vendorid: '',
                title: '',
                imagename: '',
                telephone: '',
                faxphone: '',
                mobilephone: '',
                // activestatus: '1',
                vendornote: '01'
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    vendorid: '',
                    title: '',
                    itemnname: '',
                    telephone: '',
                    faxphone: '',
                    mobilephone: '',
                    vendornote: '01',
                    totalrecord: 1
                }],
            itempage_listimage: [
                {

                    imagename: '',
                    totalrecord: 1
                }],
            itempage_listimage2: [
                {
                    imagename: '',
                    totalrecord: 1
                }],




            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            companycode: '01',
            totalrecord: 0,
            pagecount: 0,
            title: '',
            itemnname: '',
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (id, callBack) => {
        try {

            let userid = sessionStorage.getItem("userid");

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: id,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: userid
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stockngc/Productliststock_ngc_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context report getpage productlistbybrand 3  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })


        } catch (error) {
            AlertError("error->" + error.message);
            // callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getMenubyUserid = async (userid, callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.post(url, { data: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getData = async (code, callBack) => {
        try {


            await axios.get(ServiceApi, { params: { code: code } })
                .then((response) => {

                    // AlertError("context from server  getdata ->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };



    getImage = async (code, callBack) => {
        try {

            // alert("sssssqqqqqqq"  + code);
            //alert("service api ->" + ServiceApi)

            let register = sessionStorage.getItem("register");
            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: code,
                register: register,
                companycode: companycode,
                userid: userid
            }


            let url = BaseApi + "/api/stock/Productdetail_image"



            await axios.post(url, { data: page })
                .then((response) => {

                    //  alert("context from server  getdata ->" + JSON.stringify(response.data));


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // itempage: response.data,
                            itempage_listimage: response.data,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)



                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };



    getImage_2 = async (code, callBack) => {
        try {

            // alert("sssssqqqqqqq"  + code);
            //alert("service api ->" + ServiceApi)

            let register = sessionStorage.getItem("register");
            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: code,
                register: register,
                companycode: companycode,
                userid: userid
            }


            let url = BaseApi + "/api/stock/Productdetail_image"



            await axios.post(url, { data: page })
                .then((response) => {

                    // AlertError("context from server  getdata ->" + JSON.stringify(response.data));


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // itempage: response.data,
                            itempage_listimage2: response.data,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)



                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getAll = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/dealer/dealer_user_all", { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //alert("user context save -> " + ServiceApi)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }





    getSummary = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stockngc/productcurrentstock_page";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //   alert("context report summary by month- getSummary  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };




    Myresize = async (xdata, callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stockngc/Productdetail_resizeimage_ngc";

            let register = sessionStorage.getItem("register");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    // savedataToTable = async (xdata, callBack) => {

    //     try {

    //      alert("context Myresize->" + JSON.stringify(xdata));

    //         let url = BaseApi + "/api/stockngc/Productdetail_savedataToTable_ngc";

    //         let page = {

    //             register: xdata,

    //         }

    //         //alert("inbox context get page")

    //         // let url = BaseApi + "/api/stock/Productliststock_surek_page";

    // //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


    //         await axios.post(url, {
    //             data: page
    //         })
    //             .then((res) => {
    //                 if (res.data === "true") {
    //                     callBack(true)
    //                 }
    //             })

    //     } catch (error) {
    //         AlertError(error.message);
    //         callBack(false)
    //     }
    // }
    // //function
    // componentDidMount() {
    //     //this.getData();    
    // }




    MyListImage = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_listimage";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let dn_no = sessionStorage.getItem("dn_no");
            let page = {

                register: register,
                companycode: companycode,
                dn_no: dn_no,

            }



            // alert("context getpage MyListImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    // alert("callback MyListImage" + res)
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    getImageshow = async (code, callBack) => {
        try {

            // alert("sssssqqqqqqq"  + code);
            //alert("service api ->" + ServiceApi)
            let userid = 'sa';
         
            let register = sessionStorage.getItem("register");

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: code,
                register: register,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: sessionStorage.getItem("companycode"),
                userid: userid
            }


            let url = BaseApi + "/api/stock/Productdetail_imageshow"



            await axios.post(url, { data: page })
                .then((response) => {

                    //   AlertError("context from server  getdata ->" + JSON.stringify(response.data));


                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        // totalrecord: response.data[0].totalrecord,
                        // pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)




                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };



    MyListImageshow = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_listimage";
            let companycode = sessionStorage.getItem("companycode");

            let page = {

                register: xdata,
                companycode: companycode,

            }


            await axios.post(url, {
                data: page
            })
                .then((res) => {


                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    MyDeleteImage = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deleteimage";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");
            let imagecategory = sessionStorage.getItem("imagecategory");



            let page = {

                imagename: imagename,
                register: register,
                imagecategory:imagecategory,
                companycode: companycode,

            }


        //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    MySetPreview = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_setpreview";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");
            let userid = sessionStorage.getItem("userid");

            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,
                userid: userid

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    getPageImage = async (code, callBack) => {
        try {


            let companycode = sessionStorage.getItem("companycode");
            let userid = sessionStorage.getItem("userid");

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: code,
                companycode: companycode,
                userid: userid
            }

            // alert("context getPageImage  ->" + JSON.stringify(page));

            let url = BaseApi + "/api/stock/Productdetail_pageimage"

            await axios.post(url, { data: page })
                .then((response) => {


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,

                            itempage_listimage: response.data,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };



    MyListImage_360 = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_listimage_360";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let dn_no = sessionStorage.getItem("dn_no");
            let page = {

                register: register,
                companycode: companycode,
                dn_no: dn_no,

            }



            // alert("context getpage MyListImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    // alert("callback MyListImage" + res)
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    MyListImage_panorama = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_listimage_panorama";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let dn_no = sessionStorage.getItem("dn_no");
            let page = {

                register: register,
                companycode: companycode,
                dn_no: dn_no,

            }



            // alert("context getpage MyListImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    // alert("callback MyListImage" + res)
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    MyDeleteImage_360 = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deleteimage_360";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");



            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    MyDeleteImage_panorama = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deleteimage_panorama";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");



            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

     
    
    MyDeleteFilelist = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deletefilelist";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");



            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,

            }


        //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    // if (res.data === "true") {
                    //     callBack(true)
                    // }

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)


                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    MySetDelete = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_setdelete";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");
            let userid = sessionStorage.getItem("userid");

            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,
                userid: userid

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }


    MyDeleteFilelistImage = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deletefilelistimage";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register"); 
            let userid = sessionStorage.getItem("userid");

            let page = {

                register: register,
                companycode: companycode,
                userid: userid

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }


    

    MyListImage_Sold = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_listimage_sold";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let dn_no = sessionStorage.getItem("dn_no");
            let page = {

                register: register,
                companycode: companycode,
                dn_no: dn_no,

            }



            // alert("context getpage MyListImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    // alert("callback MyListImage" + res)
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

     
    MyDeleteImage_Sold = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_deleteimage_sold";
            let companycode = sessionStorage.getItem("companycode");
            let register = sessionStorage.getItem("register");
            let imagename = sessionStorage.getItem("imagename");



            let page = {

                imagename: imagename,
                register: register,
                companycode: companycode,

            }


        //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    
    testApi = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/fetchCars2";
         
 
    let searchParams = ""
    let paginationParams = ""

    let carTypeName = ""
    let carBrandName = "Mazda"
    let carModelName = ""
    let carGear = ""
    let carFuel = ""
    let carColor = ""
    let maxYear = ""
    let minYear = ""
    let maxPrice = ""
    let maxMileage = ""
    let isNew = ""
    let isPromotion = ""
    let searchTerm = ""

    let selectedPage = 1
    let per_page = 8

    let field = ""
    let type = ""



            let page = {

                searchParams: searchParams,

                paginationParams: paginationParams,
                carTypeName: carTypeName,
                carBrandName: carBrandName,
                carModelName: carModelName,
                carGear: carGear,
                carFuel: carFuel,
                carColor: carColor,
                maxYear: maxYear,
                minYear: minYear,
                maxPrice: maxPrice,
                maxMileage: maxMileage,
                isNew: isNew,
                isPromotion: isPromotion,
                searchTerm: searchTerm,
                selectedPage: selectedPage,
                per_page: per_page,
                field: field,
                type: type,
                 

            }


        //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    // if (res.data === "true") {
                    //     callBack(true)
                    // }

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)


                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }



    MyListImage_v2 = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/listimage_v2";

            let companycode = sessionStorage.getItem("companycode");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let register = sessionStorage.getItem("register");
            let dn_no = sessionStorage.getItem("dn_no");
            let page = {

                register: register,
                imagecategory:imagecategory,
                companycode: companycode,
                dn_no: dn_no,

            }



            // alert("context getpage MyListImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    // alert("callback MyListImage" + res)
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }
 

    MymoveFile = async (callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_moveimage_surek";

            let register = sessionStorage.getItem("register");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let imagename = sessionStorage.getItem("imagename");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                imagecategory: imagecategory,
                imagename: imagename,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    
    MymoveFile_01 = async (callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_moveimage_01_surek";

            let register = sessionStorage.getItem("register");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let imagename = sessionStorage.getItem("imagename");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                imagecategory: imagecategory,
                imagename: imagename,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }


    MymoveFile_02 = async (callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_moveimage_02_surek";

            let register = sessionStorage.getItem("register");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let imagename = sessionStorage.getItem("imagename");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                imagecategory: imagecategory,
                imagename: imagename,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    
    MymoveFile_03 = async (callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_moveimage_03_surek";

            let register = sessionStorage.getItem("register");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let imagename = sessionStorage.getItem("imagename");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                imagecategory: imagecategory,
                imagename: imagename,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }


    MymoveFile_04 = async (callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/stock/Productdetail_moveimage_04_surek";

            let register = sessionStorage.getItem("register");
            let imagecategory = sessionStorage.getItem("imagecategory");
            let imagename = sessionStorage.getItem("imagename");
            let companycode = sessionStorage.getItem("companycode");
            let page = {

                register: register,
                imagecategory: imagecategory,
                imagename: imagename,
                companycode: companycode,
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    render() {
        return (
            <Productdetailbyregister_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    getImage: this.getImage,
                    getImage_2: this.getImage_2,
                    getImageshow: this.getImageshow,
                    getPageImage: this.getPageImage,
                    deleteData: this.deleteData,
                    Myresize: this.Myresize,
                    MyListImage: this.MyListImage,
                    MyListImage_v2: this.MyListImage_v2,
                    MyListImage_panorama: this.MyListImage_panorama,
                    MyListImage_360: this.MyListImage_360,
                    MyDeleteImage: this.MyDeleteImage,
                    MyDeleteImage_360: this.MyDeleteImage_360,
                    MyDeleteImage_panorama: this.MyDeleteImage_panorama,
                    MySetPreview: this.MySetPreview,
                    MyListImageshow: this.MyListImageshow,
                    // savedataToTable:this.savedataToTable,
                    getPage: this.getPage,
                    getSummary: this.getSummary,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getAll: this.getAll,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu,
                    getMenubyUserid: this.getMenubyUserid,
                    saveAuthorize: this.saveAuthorize,
                    MyDeleteFilelist: this.MyDeleteFilelist,
                    MySetDelete: this.MySetDelete,
                    MyDeleteFilelistImage: this.MyDeleteFilelistImage,
                    MyListImage_Sold: this.MyListImage_Sold,
                    MyDeleteImage_Sold: this.MyDeleteImage_Sold,
                    testApi: this.testApi,
                    MymoveFile:  this.MymoveFile,
                    MymoveFile_01:  this.MymoveFile_01,
                    MymoveFile_02:  this.MymoveFile_02,
                    MymoveFile_03:  this.MymoveFile_03,
                    MymoveFile_04:  this.MymoveFile_04,

                }}
            >
                {this.props.children}
            </Productdetailbyregister_Context.Provider>

        );
    }
}
const Productdetailbyregister_Consumer = Productdetailbyregister_Context.Consumer;

export { Productdetailbyregister_Provider, Productdetailbyregister_Consumer, Productdetailbyregister_Context };

export function withProductdetailbyregister_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Productdetailbyregister_Consumer>
                {value => <Component {...props} context={value} />}
            </Productdetailbyregister_Consumer>
        );
    };
}

