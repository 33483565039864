import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
import { message } from 'antd'
import {
    AlertCompleSave,
    AlertError,
} from '../components/Utility/MyString';

const ProductSearch_surekContext = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/stock/productcurrentstock_page";

 
 


export default class ProductSearch_surekProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                vendorid: '',
                vendorname: '',
                curentaddress: '',
                telephone: '',
                faxphone: '',
                mobilephone: '',
                // activestatus: '1',
                vendornote: '01'
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    brand: '',
                    model: '',
                    register: '',
                    launchyear: '',
                    transmission: '',
                    color: '',
                    kmcount: '',
                    province: '',
                    price: '',
                    locate: '',
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strbrand: '',
            strmodel: '',
            strfromyear: '',
            strendyear: '',
            strfromprice: '',
            strendprice: '',
            companycode: '01',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }


    getPage = async (callBack) => {
        try {


            let xstrsearch = sessionStorage.getItem("strsearch")
            // alert("product context getpage strbrand productsearch -> " + this.state.strbrand);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                // strbrand: this.state.strbrand,
                // strmodel: this.state.strmodel, 
                // strfromyear: this.state.strfromyear,
                // strendyear: this.state.strendyear,
                // strfromprice: this.state.strfromprice,
                // strendprice: this.state.strendprice,
                // companycode: this.state.companycode,
                strsearch: xstrsearch,
                userid: 'sa'
            }



            // let page = {
            //     pageindex: this.state.pageindex,
            //     pagesize: this.state.pagesize,
            //     strsearch: this.state.strsearch,
            //     // strbrand: this.state.strbrand,
            //     // strmodel: this.state.strmodel,
            //     // strfromyear: this.state.strfromyear,
            //     // strendyear: this.state.strendyear,
            //     // strfromprice: this.state.strbrastrfrompricend,
            //     // strendprice: this.state.strendprice,
            //     strfromdate: this.state.strfromdate,
            //     strenddate: this.state.strenddate,
            //     companycode: this.state.state.companycode,
            //     userid: 'sa'
            // }

            // alert("inbox context get page productsearch wwww" + this.state.strmodel)

            let url = BaseApi + "/api/stock/productcurrentstock_surek_page";

            //  alert("context getpage report  ssssss by wwww->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context report summary by sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getMenubyUserid = async (userid, callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.post(url, { data: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getData = async (userid, callBack) => {
        try {

            //alert(userid);
            //alert("service api ->" + ServiceApi)

            await axios.get(ServiceApi, { params: { code: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getBrand = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/stock/productcurrentstock_surek_page", { params: { userid: "" } })
                .then((response) => {

                    //  AlertError("context from server brand getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //alert("user context save -> " + ServiceApi)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, callBack) => {


        this.setState({


            ...this.state,
            strbrand: str1.strbrand,
            strmodel: str1.strmodel,
            strfromyear: str1.strfromyear,
            strendyear: str1.strendyear,
            strfromprice: str1.strfromprice,
            strendprice: str1.strendprice

            //  strenddate: str2 

        });
        let obj = this
        setTimeout(function () {
            //    alert("from productsearch brand context->" + obj.state.strbrand);
            //    alert("from productsearch  model context->" + obj.state.strmodel);
            //  alert("from productsearch endprice context->" + obj.state.strendprice);
            obj.getPage(callBack)
        }, 200)

    }



    getModel = async (xmodel, callBack) => {
        try {


            // this.setState({
            //   ...this.state,  
            //   model: xmodel
            // });

            // AlertError(JSON.stringify(xmodel))


            await axios.get(BaseApi + "/api/stock/productcurrentstock_surek_listmodel", { params: { code: xmodel } })
                .then((response) => {

                    //    AlertError("context from  qqqq brand getmodel->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };



    //function
    componentDidMount() {
        //this.getData();    
    }


    MySearchDetail = (str1, callBack) => {


        this.setState({


            ...this.state,
            strbrand: str1.strbrand,
            strmodel: str1.strmodel,
            strfromyear: str1.strfromyear,
            strendyear: str1.strendyear,
            strfromprice: str1.strfromprice,
            strendprice: str1.strendprice
 

            //  strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //    alert("from productsearch brand context->" + obj.state.strbrand);
            //    alert("from productsearch  model context->" + obj.state.strmodel);
            //  alert("from productsearch endprice context->" + obj.state.strendprice);
            obj.getPageSearchDetail(callBack)
        }, 200)

    }

    getPageSearchDetail = async (callBack) => {
        try {



            let xstrsearch = sessionStorage.getItem("strsearch")
            // alert("product context getpage strbrand productsearch -> " + this.state.strbrand);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strbrand: this.state.strbrand,
                strmodel: this.state.strmodel, 
                strfromyear: this.state.strfromyear,
                strendyear: this.state.strendyear,
                strfromprice: this.state.strfromprice,
                strendprice: this.state.strendprice,
                companycode: this.state.companycode, 
                userid: 'sa'
            }
 

            // alert("inbox context get page productsearch wwww" + this.state.strmodel)

            let url = BaseApi + "/api/stock/productlistSearchdetail_surek_page";

            //  alert("context getpage report  ssssss by wwww->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context report summary by sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    render() {
        return (
            <ProductSearch_surekContext.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    MySearchDetail: this.MySearchDetail,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getBrand: this.getBrand,
                    getModel: this.getModel,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu,
                    getMenubyUserid: this.getMenubyUserid,
                    saveAuthorize: this.saveAuthorize
                }}
            >
                {this.props.children}
            </ProductSearch_surekContext.Provider>

        );
    }
}
const ProductSearch_surekConsumer = ProductSearch_surekContext.Consumer;

export { ProductSearch_surekProvider, ProductSearch_surekConsumer, ProductSearch_surekContext };

export function withProductSearch_surekConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <ProductSearch_surekConsumer>
                {value => <Component {...props} context={value} />}
            </ProductSearch_surekConsumer>
        );
    };
}

