import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
 
import {
   
    AlertError,
} from '../components/Utility/MyString';

const ProductSearch_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/stockngc/productcurrentstock_page";

 
 


export default class ProductSearch_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                vendorid: '',
                vendorname: '',
                curentaddress: '',
                telephone: '',
                faxphone: '',
                mobilephone: '',
                // activestatus: '1',
                vendornote: '01'
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    brand: '',
                    model: '',
                    register: '',
                    launchyear: '',
                    transmission: '',
                    color: '',
                    kmcount: '',
                    province: '',
                    price: '',
                    locate: '',
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strbrand: '',
            strmodel: '',
            strfromyear: '',
            strendyear: '',
            strfromprice: '',
            strendprice: '',
            companycode: '01',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage_Quick  = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage_Quick  = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage_Quick  = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage_Quick = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }


    getPage = async (callBack) => {
        try {



            let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
        //    alert("product 1111 context getpage   productsearch -> " + xstrsearch);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strsearch: xstrsearch,
                companycode: companycode,
                userid: 'sa'
            }
 

            // alert("inbox context get page productsearch wwww" + this.state.strmodel)

            let url = BaseApi + "/api/stock/productcurrentstock_page";

        //    alert("context getpage report  ngc stock  by wwww->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //   alert("context report summary by ngc stock- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getMenubyUserid = async (userid, callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.post(url, { data: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getData = async (userid, callBack) => {
        try {

            //alert(userid);
            //alert("service api ->" + ServiceApi)

            await axios.get(ServiceApi, { params: { code: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getBrand = async (callBack) => {
        try {

        //    alert("getBrand");

            await axios.get(BaseApi + "/api/stock/productcurrentstock_pagesearch", { params: { userid: "" } })
                .then((response) => {

                    //  AlertError("context from server brand getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //alert("user context save -> " + ServiceApi)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, callBack) => {


        this.setState({


            ...this.state,
            // strbrand: str1.strbrand,
            // strmodel: str1.strmodel,
            // strfromyear: str1.strfromyear,
            // strendyear: str1.strendyear,
            // strfromprice: str1.strfromprice,
            // strendprice: str1.strendprice

            strsearch:str1.strsearch

            //  strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //   alert("from productsearch brand ->" + obj.state.strsearch);
            //    alert("from productsearch  model context->" + obj.state.strmodel);
            //  alert("from productsearch endprice context->" + obj.state.strendprice);
            obj.getPage(callBack)
        }, 200)

    }



    getModel = async (xmodel, callBack) => {
        try {


            let companycode = sessionStorage.getItem("companycode")
            let strbrand = sessionStorage.getItem("strbrand")
        

            let page = {
               
                strbrand: strbrand, 
                companycode:  companycode  
            }
 
        
            let url = BaseApi + "/api/stock/productcurrentstock_listmodel";

        //  alert("context getBuyfrom ->" + JSON.stringify(page));

            await axios.post(url, { data: page })



            // await axios.get(BaseApi + "/api/stock/productcurrentstock_listmodel", { params: { code: xmodel } })
                .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };



    //function
    componentDidMount() {
        //this.getData();    
    }

    


    MySearchDetail = (str1, callBack) => {


        this.setState({


            ...this.state,
            strbrand: str1.strbrand,
            strmodel: str1.strmodel,
            // strfromyear: str1.strfromyear,
            // strendyear: str1.strendyear,
            // strfromprice: str1.strfromprice,
            // strendprice: str1.strendprice,
            strfromdate: str1.strfromdate ,
            strenddate: str1.strenddate ,
            buyfrom: str1.buyfrom,
            parking: str1.parking,
            age:str1.age,
            sortcolumn:str1.sortcolumn,
            producttype: str1.producttype,
            stockstatus: str1.stockstatus 
 

            //  strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //    alert("from productsearch brand context->" + obj.state.strbrand);
            //    alert("from productsearch  model context->" + obj.state.strmodel);
            //  alert("from productsearch endprice context->" + obj.state.strendprice);
            obj.getPageSearchDetail(callBack)
        }, 200)

    }

 firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageSearchDetail(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageSearchDetail(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPageSearchDetail(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPageSearchDetail(callBack);
        }, 200)

    }


    getPageSearchDetail = async (callBack) => {
        try {



            // let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")
            // alert("product context getpage strbrand productsearch -> " + this.state.strbrand);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strbrand: this.state.strbrand,
                strmodel: this.state.strmodel, 
                // strfromyear: this.state.strfromyear,
                // strendyear: this.state.strendyear,
                // strfromprice: this.state.strfromprice,
                // strendprice: this.state.strendprice,
                // strfromdate: this.state.strfromdate ,
                // strenddate: this.state.strenddate ,
                buyfrom: this.state.buyfrom,
                parking: this.state.parking,
                age:this.state.age,
                sortcolumn:this.state.sortcolumn,
                producttype: this.state.producttype,
                stockstatus: this.state.stockstatus , 
                companycode:  companycode, 
                userid: userid
            }
 
 

            let url = BaseApi + "/api/stock/productlistSearchdetail_page";

            // alert("context getpage report  ssssss by wwww->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //    alert("context  getPageSearchDetail  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    
    getBuyfrom = async (xdata, callBack) => {
        try {
  

            let companycode = sessionStorage.getItem("companycode")
            let strbrand = sessionStorage.getItem("strbrand")
            let strmodel = sessionStorage.getItem("strmodel")

            let page = {
               
                strbrand: strbrand,
                strmodel: strmodel,  
                companycode:  companycode  
            }
 
            
            let url = BaseApi + "/api/stock/productcurrentstock_listbuyfrom";

        //  alert("context getBuyfrom ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


            .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getParking = async (xcode, callBack) => {
        try {


         


            await axios.get(BaseApi + "/api/stock/productcurrentstock_listparking", { params: { code: xcode } })
                .then((response) => {

                    //    AlertError("context from  qqqq brand getmodel->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getProducttype = async (xcode, callBack) => {
        try {
 
            await axios.get(BaseApi + "/api/stock/productcurrentstock_listproducttype", { params: { code: xcode } })
                .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getStockstatus = async (xcode, callBack) => {
        try {
 
            await axios.get(BaseApi + "/api/stock/productcurrentstock_liststockstatus", { params: { code: xcode } })
                .then((response) => {
 
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    render() {
        return (
            <ProductSearch_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    MySearchDetail: this.MySearchDetail,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    firstPage_Quick: this.firstPage_Quick,
                    previousPage_Quick: this.previousPage_Quick,
                    nextPage_Quick: this.nextPage_Quick,
                    lastPage_Quick: this.lastPage_Quick,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getBrand: this.getBrand,
                    getModel: this.getModel,
                    getBuyfrom: this.getBuyfrom,
                    getParking:this.getParking,
                    getStockstatus:this.getStockstatus,
                    getProducttype:this.getProducttype,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu,
                    getMenubyUserid: this.getMenubyUserid,
                    saveAuthorize: this.saveAuthorize
                }}
            >
                {this.props.children}
            </ProductSearch_Context.Provider>

        );
    }
}
const ProductSearch_Consumer = ProductSearch_Context.Consumer;

export { ProductSearch_Provider, ProductSearch_Consumer, ProductSearch_Context };

export function withProductSearch_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <ProductSearch_Consumer>
                {value => <Component {...props} context={value} />}
            </ProductSearch_Consumer>
        );
    };
}

