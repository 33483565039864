import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
import {message} from 'antd'
import {   
  AlertError   
} from '../components/Utility/MyString';

//import MyLoading from '../components/Utility/MyLoading';

const ShoppingCartContext = React.createContext();
const ServiceApi = BaseApi + "/api/shoppingcart" 

export default class ShoppingCartProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userid : "",
      data: {
        userid : "",
        itemcode: "",
        itemname: "",
        unitcode: "EA",
        qty: 0,
        price: 0,
        amount: 0,
        discountword: '',
        discountamount: 0,
        netamount: 0,             
        companycode: ''  
     },
     items: []     
    }
  }

  getItemCount = () => {
      return(this.state.items.length)
  }

  setShoppingCartUserid = async (xiuserid) => {

    this.setState({
      //...this.state,  
      userid : xiuserid
    }); 

    let obj = this
    setTimeout(function(){
      //alert("shopingcart userid->" + obj.state.userid);
      obj.getData(obj.state.userid,obj.dummyCallbak)
    },100)

  };
  
  dummyCallbak=function(){

  }

  setData = async (values) => {

    this.setState({
      ...this.state,  
      data : values
    }); 

  };
 
 //function getData
 getData = async (userid,callBack) => {
    try {
 
      //alert(userid);
      
      await axios.get(ServiceApi,{params : {userid: userid}})
        .then((response)=>{

          //AlertError("context  shopping from server->" + JSON.stringify(response.data));
       
          this.setState({
              //...this.state,               
              items : response.data,              
          });

          callBack()
        })
      
      
    } catch (error) {
      AlertError(error.message);
      callBack()
    }
  };

  //function SaveData
  saveData = async (xdata,callBack) => { 

    try{
 
      //callBack()
      xdata.userid = this.state.userid

      //alert("context item ->" + JSON.stringify(xdata));
      
      await axios.post(ServiceApi,{        
        data: {
          userid : xdata.userid,
          itemcode : xdata.itemcode,
          itemname : xdata.itemname,
          unitcode : xdata.unitcode,
          qty : 1,
          price : xdata.price,
          amount : xdata.amount,
          discountword : xdata.discountword,
          discountamount : xdata.discountamount,
          netamount : xdata.netamount
        }
      })
      .then((res)=>{
        if(res.data==="true"){
          //callBack()
          //get all data in cart before callback
          this.getData(this.state.userid,callBack)
        }            
      })     
      
      

    }catch(error){
      AlertError(error.message);
      callBack()
    }
  }

  //function Update Qty
  updateData = async (xdata,callBack) => { 

    try{
 
      //callBack()
      xdata.userid = this.state.userid

      //alert("context item ->" + JSON.stringify(xdata));
      
      await axios.put(ServiceApi,{        
        data: {
          rowid : xdata.rowid,
          userid : xdata.userid,
          itemcode : xdata.itemcode,
          qty : xdata.qty          
        }
      })
      .then((res)=>{
        if(res.data==="true"){
          //callBack()
          //get all data in cart before callback
          this.getData(this.state.userid,this.dummyCallbak)
        }            
      })     
      
      

    }catch(error){
      AlertError(error.message);
      callBack()
    }
  }

  deleteData = async (xdata,callBack) => { 

    try{

      //alert("delete data from context")
 
      message.loading('Action in progress..',1);      

      await axios.delete(ServiceApi,{
        data: {
          rowid : xdata.rowid,
          userid : xdata.userid,
          itemcode : xdata.itemcode                   
        }
      })
      .then((res)=>{
        if(res.data==="true"){

          let xobj = this

          setTimeout(function(){
              message.success('Delete Data Complete..', 3);
              xobj.getData(xobj.state.userid,xobj.dummyCallbak)
              callBack()
          },500)
        }            
      })    

    }catch(error){
      AlertError(error.message);
    }
  }

  componentDidMount(){
    //this.getData(this.state.userid,"")
  }
 
   
  render() {
    return (
 
      <ShoppingCartContext.Provider
        value={{
          ...this.state,          
          handleChange: this.handleChange,
          setData: this.setData,
          setShoppingCartUserid : this.setShoppingCartUserid,
          saveData: this.saveData,
          getData: this.getData,
          updateData: this.updateData,
          deleteData : this.deleteData,           
        }}
      >
        {this.props.children}
      </ShoppingCartContext.Provider>
    );
  }
}
const ShoppingCartConsumer = ShoppingCartContext.Consumer;

export { ShoppingCartProvider, ShoppingCartConsumer, ShoppingCartContext };

export function withShoppingCartConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <ShoppingCartConsumer>
        {value => <Component {...props} context={value} />}
      </ShoppingCartConsumer>
    );
  };
}
