import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
 
import {
    // AlertCompleSave,
    AlertError,
} from '../../components/Utility/MyString';

const Filekeep_Context = React.createContext();
 

const ServiceApi = BaseApi + "/api/sales/db_save";
 
export default class Filekeep_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: { 
                fk_group_id:'',
                fk_type_id :'',
                register:'',
                docno :'',
                link_url:'',
                imagename:'',
                companycode:'',
                remark:'' 
          
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    fk_group_id:'',
                    fk_type_id :'',
                    register:'',
                    docno :'',
                    link_url:'',
                    imagename:'',
                    companycode:'',
                    remark:'', 
                    totalrecord: 1
                }], 

                itempage_listimage_activity: [
                    {
                        
                        item_no:'', 
                        imagename:'',
                        companycode:'',
                        remark:'', 
                        totalrecord: 1
                    }],

               itempage_activity: [
                        {
                            
                            item_no:'', 
                            itemname:'',
                            activitydate:'',
                            remark:'', 
                            totalrecord: 1
                        }],
    


                    
                
            pageindex: 1,
            pagesize: 20,
            strsearch: '',  
            companycode: '',
            totalrecord: 0, 
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize, 
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }
 

            let url = BaseApi + "/api/filekeep/filekeep_page";

       

            await axios.post(url, { data: page })


                .then((response) => {

            
                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            // totalrecord: response.data[0].totalrecord, 
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                    //     callBack(response.data)
                    // }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

     
 

    //select changed
    handleChange = (str1, callBack) => {


        this.setState({
 
            ...this.state,
         
            strsearch:str1.strsearch
 

        });
        let obj = this
        setTimeout(function () {
         
            obj.getPage(callBack)
        }, 200)

    }


    
 

    //function
    componentDidMount() {
        //this.getData();    
    }

    getRunning = async (code,callBack) => {
        try {
    
          //alert("product context getpage -> " + this.state.strsearch);
        let page = {

                fk_group_id :sessionStorage.getItem("fk_group_id") ,
                fk_type_id :sessionStorage.getItem("fk_type_id") ,
                register: sessionStorage.getItem("register"),
            
          }
    
          //alert("inbox context get page") 
          let url = BaseApi + "/api/filekeep/filekeep_running";
   
    
        //  alert("context getpage ->" + JSON.stringify(page));
           
          await axios.post(url, { data : page })
            .then((response)=>{
    
            //  alert("context get running ->" + JSON.stringify(response.data));
      
              this.setState({
                ...this.state,
                data : {
                    filename : response.data[0].filename, 
                    // cusdb_id : response.data[0].cusdb_id, 
                    // jobdate : response.data[0].jobdate, 
                    // jobtime : response.data[0].jobtime, 
                   
                },
                dataitem : [],
                datadealer : []
            }) 
    
            let xobj = this
            setTimeout(function(){
              callBack(xobj.state.data)
            },100)
                     
            })
          
          
        } catch (error) {
          //AlertError("error->" + error.message);
          callBack("")
        }
      };
     

    
    Myresize = async (xdata, callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/filekeep_resizeimage";

            let page = {
              
                register: sessionStorage.getItem("register"),
                fk_group_id :sessionStorage.getItem("fk_group_id") ,
                fk_type_id :sessionStorage.getItem("fk_type_id") ,
                filename :sessionStorage.getItem("filename") ,
                companycode :sessionStorage.getItem("companycode") 
                
            }
 
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
  
    

    
    getPageFileType = async (xdata,callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let fk_group_id = sessionStorage.getItem("fk_group_id")
            let register = sessionStorage.getItem("register")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                fk_group_id: fk_group_id,
                register: register,  
                companycode: companycode,
                userid: userid
            }

        //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/filetype_page";

        // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data
                            // totalrecord: response.data[0].totalrecord, 
                            // pagecount: xpagecount
                        });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    

    
    getFileListPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let fk_group_id = sessionStorage.getItem("fk_group_id")
            let fk_type_id = sessionStorage.getItem("fk_type_id")
            let register = sessionStorage.getItem("register")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                fk_group_id: fk_group_id,
                fk_type_id: fk_type_id,  
                register: register,
                companycode: companycode,
                userid: userid
            }

        //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/filelist_page";

        // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    // let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    // let ypage = response.data[0].totalrecord % 20;
                    // if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                       
                         
                        });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    
      
    MyListFile = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/filekeep_listfile";

            let page = {
              
                register: sessionStorage.getItem("register"),
                fk_group_id :sessionStorage.getItem("fk_group_id") ,
                fk_type_id :sessionStorage.getItem("fk_type_id") ,
                filename :sessionStorage.getItem("filename"), 
                companycode :sessionStorage.getItem("companycode") 
                
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
 

    MyDeleteImage = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/filekeep_deleteimage";
           
          
        

            let page = {
              
                register: sessionStorage.getItem("register"),
                fk_group_id :sessionStorage.getItem("fk_group_id") ,
                fk_type_id :sessionStorage.getItem("fk_type_id") ,
                filename :sessionStorage.getItem("imagename"), 
                companycode :sessionStorage.getItem("companycode") 
 
                
            }
 

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    
    
      
    getfk_group_id = async (callBack) => {

        try { 
            let url = BaseApi + "/api/filekeep/filekeep_getfk_group_id";

            let page = {
              
                // register: sessionStorage.getItem("register"),
                // fk_group_id :sessionStorage.getItem("fk_group_id") ,
                // fk_type_id :sessionStorage.getItem("fk_type_id") ,
                // filename :sessionStorage.getItem("filename"), 
                companycode :sessionStorage.getItem("companycode") 
                
            } 
            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    this.setState({
                        ...this.state,               
                        itempage : res.data,              
                    });
                   
                    
                    setTimeout(function () {
                        callBack(res.data)
                    }, 500)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

      
    getfk_type_id = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/filekeep_getfk_type_id";

            let page = {
              
                // register: sessionStorage.getItem("register"),
                 fk_group_id :sessionStorage.getItem("fk_group_id") ,
                // fk_type_id :sessionStorage.getItem("fk_type_id") ,
                // filename :sessionStorage.getItem("filename"), 
                companycode :sessionStorage.getItem("companycode") 
                
            }
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    this.setState({
                        ...this.state,               
                        itempage : res.data,              
                    });
                  
                    setTimeout(function () {
                        callBack(res.data)
                    }, 500)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    
    
    SaveActivity = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);
 
            let page = {
                item_no: sessionStorage.getItem("item_no"),
                activitydate: sessionStorage.getItem("activitydate"),  
                itemname: sessionStorage.getItem("itemname"),
                remark: sessionStorage.getItem("remark"),
                userid: sessionStorage.getItem("userid"),
                companycode:  sessionStorage.getItem("companycode") 
                
            }

        //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/saveactivity";

        // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

               

                    let obj = this

                    // setTimeout(function () {
                        // obj.setState({
                        //     ...obj.state,
                        //     itempage: response.data,
                       
                         
                        // });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    

    Myresize_Activity = async (xdata, callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/activity_resizeimage";

            let page = {
              
                item_no: sessionStorage.getItem("item_no"), 
                companycode :sessionStorage.getItem("companycode") 
                
            }
 
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

     
     
    MyListImage_Activity = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/activity_listfile";

            let page = {
              
                item_no: sessionStorage.getItem("item_no"), 
                userid :sessionStorage.getItem("userid"), 
                companycode :sessionStorage.getItem("companycode") 
                
            }

            //alert("inbox context get page")

            // let url = BaseApi + "/api/stock/Productliststock_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
 
    

    
    getImage_Activity = async (callBack) => {
        try {
 

            let page = {

                item_no: sessionStorage.getItem("item_no"),
                userid: sessionStorage.getItem("userid")
            }


           
            let url = BaseApi + "/api/filekeep/filelist_activity";



            await axios.post(url, { data: page })
                .then((response) => {

                    // AlertError("context from server  getdata ->" + JSON.stringify(response.data));


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // itempage: response.data,
                            itempage_listimage_activity: response.data,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)



                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };



    

    getPageActivityImage = async (callBack) => {
        try {
 

            let page = { 
              
                userid: sessionStorage.getItem("userid")
            }


           
            let url = BaseApi + "/api/filekeep/activity_listpage";



            await axios.post(url, { data: page })
                .then((response) => {

            //   alert("context from server  getdata ->" + JSON.stringify(response.data.data));


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,


                            itempage_activity:  response.data.data ,
                            itempage_listimage_activity: response.data.activityimage,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)



                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };
 

    getActivity = async (callBack) => {
        try {
 

            let page = { 
                item_no: sessionStorage.getItem("item_no"),
                userid: sessionStorage.getItem("userid")
            }


           
            let url = BaseApi + "/api/filekeep/activity_getdata";



            await axios.post(url, { data: page })
                .then((response) => {

            //   alert("context from server  getdata ->" + JSON.stringify(response.data.data));


                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,


                         
                            itempage_listimage_activity: response.data.activityimage,
                         
                        });
                    }, 100)



                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };


    

    

    MyDeleteImage_Activity = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));
 
            let url = BaseApi + "/api/filekeep/activity_deleteimage";
           
          
        

            let page = {
              
                item_no: sessionStorage.getItem("item_no"), 
                filename :sessionStorage.getItem("imagename"), 
                companycode :sessionStorage.getItem("companycode") 
 
                
            }
 

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));
 
          
            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    

    
    getRunning_Activity = async (callBack) => {
        try {
    
          //alert("product context getpage -> " + this.state.strsearch);
        let page = {
 
                companycode: sessionStorage.getItem("companycode"),
            
          }
    
          //alert("inbox context get page") 
          let url = BaseApi + "/api/filekeep/activity_running";
   
    
        //  alert("context getpage ->" + JSON.stringify(page));
           
          await axios.post(url, { data : page })
            .then((response)=>{
    
            
    
            setTimeout(function () {
                callBack(response.data);
            }, 100);
                     
            })
          
          
        } catch (error) {
          //AlertError("error->" + error.message);
          callBack("")
        }
      };
     


    render() {
        return (
            <Filekeep_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData, 
                    getRunning: this.getRunning,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    deleteData: this.deleteData,
                    updateData: this.updateData,
                    getPage: this.getPage,
                    getPageFileType: this.getPageFileType,
                    getfk_group_id : this.getfk_group_id,
                    getfk_type_id : this.getfk_type_id,
                    Myresize: this.Myresize,
                    getFileListPage: this.getFileListPage,
                    MyDeleteImage: this.MyDeleteImage,
                    MyListFile:this.MyListFile  ,
                    SaveActivity:this.SaveActivity,
                    Myresize_Activity: this.Myresize_Activity,
                    MyListImage_Activity: this.MyListImage_Activity,
                    getImage_Activity: this.getImage_Activity,
                    getPageActivityImage: this.getPageActivityImage,
                    getActivity: this.getActivity,
                    MyDeleteImage_Activity: this.MyDeleteImage_Activity,
                    getRunning_Activity : this.getRunning_Activity
                  
                }}
            >
                {this.props.children}
            </Filekeep_Context.Provider>
            
        );
        
    }
}
const Filekeep_Consumer = Filekeep_Context.Consumer;

export { Filekeep_Provider, Filekeep_Consumer, Filekeep_Context };

export function withFilekeep_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Filekeep_Consumer>
                {value => <Component {...props} context={value} />}
            </Filekeep_Consumer>
        );
    };
}

