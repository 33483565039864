import React, {useState,forwardRef, useImperativeHandle} from 'react'
import { Modal ,Typography ,Alert} from 'antd'

const {Text} = Typography

const TextConfirmSave = 'ต้องการบันทึกข้อมูลใช่หรือไม่ ??'
const TextConfirmUpdate = 'ต้องการแก้ไขมูลใช่หรือไม่ ??'
const TextConfirmDelete = 'ต้องการลบข้อมูลใช่หรือไม่ ??'

const TextCompleteSave = 'บันทึกข้อมูลเรียบร้อยแล้ว !!'
const TextCompleteUpdate = 'แก้ไขมูลเรียบร้อยแล้ว !!'
const TextCompleteDelete = 'ลบข้อมูลเรียบร้อยแล้ว !!'

const TextTitleConfirm = 'Confirm'

const confirm = Modal.confirm
  

const AlertCompleSave= async()=>{ 
  Modal.success({
    title: 'Result',
    content: TextCompleteSave,
  });
  /*   
  confirm({
      title: 'Result',
      content: xxcomplete,
      onOk() {
          //callbackConfirm(true)
      },
      onCancel() {
          //callbackConfirm(false)
      },
    })       
    */
}



const AlertCompleUpdate= async()=>{    
  Modal.success({
    title: 'Result',
    content: TextCompleteUpdate,
  });
}

const AlertCompleDelete= async()=>{    
  Modal.success({
    title: 'Complete',
    zIndex : "9999999",
    content: TextCompleteDelete,
  }); 
}

const AlertError= async(message)=>{    
  Modal.error({
    title: 'Error',
    content: message,
  });
}

const AlertNormal= async(title,message)=>{    
  Modal.info({
    title: title,
    content: (
      <div>
        <p>{message}</p>
        <p>...</p>
      </div>
    ),
    onOk() {},
  }); 
}

const AlertInfo = async(title,message) => {
  //success
  Modal.success({
    title: title,
    content: (
      <div>
        <p>{message}</p>
        <p>...</p>
      </div>
    ),
    onOk() {},
  }); 
}

const ShowConfirmSave= async(callbackConfirm)=>{    
    confirm({
        title: TextTitleConfirm,
        content: TextConfirmSave,
        onOk() {
            callbackConfirm(true)
        },
        onCancel() {
            callbackConfirm(false)
        },
      })       
}

const ShowConfirmUpdate= async(callbackConfirm)=>{    
    confirm({
        title: TextTitleConfirm,
        content: TextConfirmUpdate,
        onOk() {
            callbackConfirm(true)
        },
        onCancel() {
            callbackConfirm(false)
        },
      })       
}

const ShowConfirmDelete= async(data,callbackConfirm)=>{    
    confirm({
        title: TextTitleConfirm,
        content: TextConfirmDelete,
        zIndex : "999999",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            callbackConfirm(data,true)
        },
        onCancel() {
            callbackConfirm(data,false)
        },
      });
}

const AlertWarning= async(title,message)=>{    
  Modal.warning({
    title: title,
    zIndex : "9999999",
    content: (
      <div>
        <p>{message}</p> 
      </div>
    ),
    onOk() {},
  }); 
}

//myString = useRef  ,  myString.current.getAlert();
const MyAlert = forwardRef((props, ref) => {

    const [visible , setVisible] = useState(false)
    const [title , setTitle] = useState('')
    const [content , setContent] = useState('')
   
     useImperativeHandle(ref, () => ({
  
      getAlert() {
        alert("getAlert from Child");
      }     
      ,
      AlertCompleteSave() {
        setTitle('Result')
        setContent(TextCompleteSave)
        ShowModal();
      },
      AlertCompleteUpdate() {
        setTitle('Result')
        setContent(TextCompleteUpdate)
        ShowModal();
      },
      AlertCompleteDelete() {
        setTitle('Result')
        setContent(TextCompleteDelete)
        ShowModal();
      },
      AlertError(message) {
        setTitle('Error')
        setContent(message)
        ShowModal();
      }                
  
    }));

    const ShowModal = () => {
        setVisible(true)
      };
    
    const hideModal = () => {
        setVisible(false)
        //props.callbackConfirm()
      };

      const hideModal2 = () => {
        setVisible(false)
        //callbackConfirm
      }; 
  
    return(
        <div>
       
        <Modal
          title={title}
          visible={visible}
          zIndex = "999999" 
          onOk={hideModal}
          onCancel={hideModal2}
          okText="OK"
          cancelText="CANCEL"
        >
          <h4>    
            <Text mark>     
            {content}
            </Text>
          </h4>

        </Modal>
      </div>
    )
  });


export {
    AlertCompleSave,  //function 
    AlertCompleUpdate, //function 
    AlertCompleDelete, //function 
    AlertError, //function 
    AlertWarning, //function
    AlertNormal, //function 
    AlertInfo,//function
    ShowConfirmSave, //function 
    ShowConfirmUpdate, //function 
    ShowConfirmDelete, //function 
    MyAlert //Component call by useRef Hook nad Place component on page
}