import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";

import {

  AlertError,
} from '../components/Utility/MyString';

const ProductContext = React.createContext();
const ServiceApi = BaseApi + "/api/admin/admin_product";

export default class ProductProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userid: '',
      data: {
        code: '',
        name1: '',
        unitcode: 'EA',
        saleprice: 0,
        activestatus: '1',
        salestatus: '1',
        linkurl: '',
        content: ''
      },
      productcode: '',
      itempage: [
        {
          register:'',
          dn_no:'',
          imageurl: '',
          locate: '',
          buyin:'',
          imagename:'',
          price: '', 
          totalrecord: 1
        }
      ],
      pageindex: 1,
      pagesize: 10,
      strsearch: '',
      companycode: '01',
      totalrecord: 0,
      pagecount: 0
    } // end set state

  }// end sonstructore


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    //console.log("state->" + JSON.stringify(this.state))

  };

  setProductUserid = async (xuserid) => {
    this.setState({
      //...this.state,  
      userid: xuserid
    });

    //console.log("state->" + JSON.stringify(this.state))

  };

  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }

  getPage = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {
        pageindex: this.state.pageindex,
        pagesize: this.state.pagesize,
        strsearch: this.state.strsearch,
        companycode: this.state.companycode,
        userid: this.state.userid
      }

      //alert("inbox context get page")

      let url = BaseApi + "/api/admin/product_page";

      //alert("context data->" + JSON.stringify(page));

      await axios.post(url, { data: page, xx: "xxx" })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          //AlertError("getpage ->" + JSON.stringify(response.data));

          let objdata = response.data;

          //AlertError("getpage ->" + JSON.stringify(objdata[0].totalrecord));



          let obj = this

          setTimeout(function () {

            if (objdata[0] !== undefined) {
              xpagecount = parseInt(response.data[0].totalrecord / 20);
              ypage = response.data[0].totalrecord % 20;
              if (ypage > 0) { xpagecount = xpagecount + 1 }
              xtotalrecord = response.data[0].totalrecord
            }

            obj.setState({
              ...obj.state,
              itempage: response.data,
              totalrecord: xtotalrecord,
              pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  getData = async (id, callBack) => {
    try {

      //message.loading('Please wait ...',2);  
      //alert('get data id=>' + id);
      this.setState({
        ...this.state,
        productcode: id
      });

      //alert("product context -> " + ServiceApi);

      await axios.get(ServiceApi, { params: { id: id } })
        .then((res) => {

          AlertError("context from server->" + JSON.stringify(res.data));

          this.setState({
            ...this.state,
            data: res.data,
          });

          callBack(res.data)
        })


    } catch (error) {
      AlertError(error.message);
      callBack("")
    }
  };

  saveData = async (xdata, callBack) => {

    try {

      //message.loading('Please wait ...',2);      

      await axios.post(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            //AlertCompleSave();

            setTimeout(function () {
              //message.success('Save Data Complete..', 5);
              callBack(true)
            }, 200)

          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  updateData = async (xdata, callBack) => {

    try {

      //message.loading('Please wait ...',2);      

      await axios.put(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            //AlertCompleSave();

            setTimeout(function () {
              //message.success('Save Data Complete..', 5);

              callBack(true)
            }, 200)

          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  deleteData = async (xdata, callBack) => {

    try {

      //alert("code->" + xdata.code + ' xcode->' + this.state.xtype)

      //message.loading('Action in progress..',1);      

      await axios.delete(ServiceApi, {
        xcode: xdata.xcode,
        code: xdata.code,
        name: xdata.name,
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            setTimeout(function () {
              //message.success('Delete Data Complete..', 5);
              callBack(true)
            }, 200)
          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  //function
  componentDidMount() {
    //this.getData();    
  }

  //select changed
  handleChange = (str, callBack) => {
    this.setState({
      //...this.state,
      strsearch: str
    });

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  MyGetAPI1 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        userid: ''
      }

      //alert("inbox context get page")

      let url = BaseApi + "/api/getCarTypes";


      await axios.post(url, { data: page })
        .then((response) => {

          AlertError(JSON.stringify(response.data));

          // let objdata = response.data;


          // let obj = this

          // setTimeout(function(){

          // if(objdata[0] !== undefined){
          //   xpagecount = parseInt(response.data[0].totalrecord / 20);
          //   ypage = response.data[0].totalrecord % 20;
          //   if(ypage > 0) { xpagecount = xpagecount + 1}
          //   xtotalrecord = response.data[0].totalrecord
          // }

          //   obj.setState({
          //     ...obj.state,               
          //     // itempage : response.data,
          //     // totalrecord: xtotalrecord,
          //     // pagecount: xpagecount
          // });
          // },100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  MyGetAPI2 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        typeId: '51'
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getCarBrands";


      await axios.post(url, { data: page })
        .then((response) => {


          AlertError(JSON.stringify(response.data));



          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };

  MyGetAPI3 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        typeId: '51',
        brandId: 'toyota'
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getCarModels";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };

  MyGetAPI4 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        userid: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getCarPromotions";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };

  MyGetAPI5 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        userid: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getCarNews";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  MyGetAPI6 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        carPrototypeId: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getRelatedCars";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  MyGetAPI7 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        carTypeId: '30',
        carBrandId: 'TOYOTA'
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getRelatedCarsByTypeExcludeBrand";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  MyGetAPI10 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        carTypeId: '',
        carBrandId: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getActiveCarBrands";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };

  MyGetAPI11 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {

        carTypeId: '',
        carBrandId: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/getActiveCarTypes";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  MyGetAPI8 = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {
 
        carTypeId: '20',
        carBrandId: '',
        carModelId: '',
        carGear: '',
        carFuel: '',
        carColor: '',
        maxYear: '',
        minYear: '',
        maxPrice: '',
        maxMileage: '',
        searchTerm: '',
        selectedPage: 1,
        per_page: 8,
        sortBy_field: '',
        sortBy_type: ''
      }

      // alert("inbox context  MyGetAPI2")

      let url = BaseApi + "/api/fetchCars";


      await axios.post(url, { data: page })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          AlertError(JSON.stringify(response.data));

          // AlertError(response.data);

          let objdata = response.data;

          console.log(objdata)

          //AlertError(JSON.stringify(objdata[0].totalrecord));

          let obj = this

          setTimeout(function () {

            // if(objdata[0] !== undefined){
            //   xpagecount = parseInt(response.data[0].totalrecord / 20);
            //   ypage = response.data[0].totalrecord % 20;
            //   if(ypage > 0) { xpagecount = xpagecount + 1}
            //   xtotalrecord = response.data[0].totalrecord
            // }

            obj.setState({
              ...obj.state,
              // itempage : response.data,
              // totalrecord: xtotalrecord,
              // pagecount: xpagecount
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };

  

  PagecheckImage = async ( callBack) => {
    try {


        let companycode = sessionStorage.getItem("companycode");
        let userid = sessionStorage.getItem("userid");
        let strsearch = sessionStorage.getItem("strsearch");
        let filter_status = sessionStorage.getItem("filter_status");
        let filter_date_from = sessionStorage.getItem("filter_date_from");
        let filter_date_to = sessionStorage.getItem("filter_date_to");

        let page = {
            pageindex: this.state.pageindex,
            pagesize: this.state.pagesize,
            filter_date_from : filter_date_from,
            filter_date_to :filter_date_to,
            filter_status :filter_status,
            strsearch: strsearch, 
            companycode: companycode,
            userid: userid  

        }

        // alert("context getPageImage  ->" + JSON.stringify(page));

        let url = BaseApi + "/api/stock/pagecheckimage"

        await axios.post(url, { data: page })
            .then((response) => {


              let xpagecount = parseInt(response.data[0].totalrecord / 10);
              let ypage = response.data[0].totalrecord % 10;
              if (ypage > 0) { xpagecount = xpagecount + 1 }

                let obj = this

                setTimeout(function () {
                    obj.setState({
                        ...obj.state,

                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                        
                    });
                }, 100)

                setTimeout(function () {
                    callBack(response.data)
                }, 100)

            })


    } catch (error) {
        AlertError(error.message);
        callBack()
    }
};


previousPageimage = async (callBack) => {
  if (this.state.pageindex === 1) {
    callBack("")
    return;
  }
  this.setState({
    ...this.state,
    pageindex: this.state.pageindex - 1
  })

  let obj = this
  setTimeout(function () {
    //alert("from context->" + obj.state.strsearch);
    obj.PagecheckImage(callBack)
  }, 200)

}

nextPageimage = async (callBack) => {

  if (this.state.pageindex === this.state.pagecount) {
    callBack("")
    return;
  }

  this.setState({
    ...this.state,
    pageindex: this.state.pageindex + 1
  })

  let obj = this
  setTimeout(function () {
    //alert("from context->" + obj.state.strsearch);
    obj.PagecheckImage(callBack)
  }, 200)

}



firstPagecheckimage = async (callBack) => {
  this.setState({
    ...this.state,
    pageindex: 1
  })

  let obj = this
  setTimeout(function () {
    //alert("from context->" + obj.state.strsearch);
    obj.PagecheckImage(callBack)
  }, 200)

}

  render() {
    return (
      <ProductContext.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          setProductUserid: this.setProductUserid,
          saveData: this.saveData,
          updateData: this.updateData,
          getData: this.getData,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          MyGetAPI1: this.MyGetAPI1,
          MyGetAPI2: this.MyGetAPI2,
          MyGetAPI3: this.MyGetAPI3,
          MyGetAPI4: this.MyGetAPI4,
          MyGetAPI5: this.MyGetAPI5,
          MyGetAPI6: this.MyGetAPI6,
          MyGetAPI7: this.MyGetAPI7,
          MyGetAPI8: this.MyGetAPI8,
          MyGetAPI10: this.MyGetAPI10,
          MyGetAPI11: this.MyGetAPI11,
          PagecheckImage: this.PagecheckImage,
          previousPageimage: this.previousPageimage,
          nextPageimage: this.nextPageimage,
          firstPagecheckimage: this.firstPagecheckimage,
        }}
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer, ProductContext };

export function withProductConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <ProductConsumer>
        {value => <Component {...props} context={value} />}
      </ProductConsumer>
    );
  };
}
