import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
import {message} from 'antd'
import {
  AlertCompleSave,
  AlertError, 
} from '../../components/Utility/MyString';

const CompanyProfileContext = React.createContext();
const ServiceApi = BaseApi + "/api/companyprofile"; 

export default class CompanyProfileProvider extends Component {
  state = {
    data: {
            companyname1: '',            
            companyaddress1: '',             
            telephone: '', 
            fax: '',
            taxnumber: '',
            teamid:'',
            branchid:'',
            taxrate: 7  
    },
    item: {code:'',name:''},
    xtype : "-1",
    loading: true,    
  };

  setData = async (values) => {
    this.setState({
      ...this.state,  
      data : values
    });

    console.log("state->" + JSON.stringify(this.state))

  };

  getData = async (callBack) => {
    try {

      message.loading('Please wait ...',2);  

      await axios.get(ServiceApi)
        .then((response)=>{

          //AlertError("context from server->" + JSON.stringify(response.data));
       
          this.setState({
              ...this.state,               
              data : response.data
          });

          setTimeout(function(){
            callBack(response.data)
          },100)

        })
      
      
    } catch (error) {
      AlertError(error);
    }
  };

  saveData = async (xdata,callBack) => { 

    try{
 
      message.loading('Please wait ...',2);     
      
      //alert("savedata context -> " + ServiceApi)
      this.setState({
        ...this.state,               
        data : xdata
      })

      await axios.post(ServiceApi,{        
        data: xdata
      })
      .then((res)=>{
        if(res.data==="true"){
          AlertCompleSave();
          /*
          setTimeout(function(){
              message.success('Save Data Complete..', 5);
              callBack()
          },2000)
          */
        }            
      })     
      

    }catch(error){
      AlertError(error);
    }
  }

  deleteData = async (xdata,callBack) => { 

    try{

      //alert("code->" + xdata.code + ' xcode->' + this.state.xtype)
 
      message.loading('Action in progress..',1);      

      await axios.delete(ServiceApi,{
        xcode: xdata.xcode,
        code: xdata.code,
        name: xdata.name,
        data: xdata
      })
      .then((res)=>{
        if(res.data==="true"){
          setTimeout(function(){
              message.success('Delete Data Complete..', 5);
              callBack()
          },2000)
        }            
      })     
      

    }catch(error){
      AlertError(error);
    }
  }

  //function
  componentDidMount() {
    //this.getData();    
  }
  
  //select changed
  handleChange = (ptype) => {      
    this.setState(
      {
        ...this.setState,
        xtype: ptype
      }
    )
  }


  getsaleteam = async (callBack) => {
    try {

        let companycode = sessionStorage.getItem("companycode")

        let page = {
            code : companycode 
            
          }

        await axios.post(BaseApi + "/api/companyprofile/getsaleteam", {   data : page })
            .then((response) => {
 
                     let obj = this

                        setTimeout(function () {
                            obj.setState({
                                ...obj.state,
                                itempage: response.data 
                        
                        
                            });
                        }, 100)
            
        
                setTimeout(function () {
                    callBack(response.data)
                }, 500)

            })


    } catch (error) {
        AlertError(error.message);
        //callBack()
    }
};


getbranch = async (callBack) => {
  try {

      let companycode = sessionStorage.getItem("companycode")

      let page = {
          code : companycode 
          
        }

      await axios.post(BaseApi + "/api/companyprofile/getbranch", {   data : page })
          .then((response) => {

                   let obj = this

                      setTimeout(function () {
                          obj.setState({
                              ...obj.state,
                              itempage: response.data 
                      
                      
                          });
                      }, 100)
          
      
              setTimeout(function () {
                  callBack(response.data)
              }, 500)

          })


  } catch (error) {
      AlertError(error.message);
      //callBack()
  }
};
   
  render() {
    return (
      <CompanyProfileContext.Provider
        value={{
          ...this.state,          
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getsaleteam: this.getsaleteam,
          getbranch: this.getbranch,
          deleteData : this.deleteData
        }}
      >
        {this.props.children}
      </CompanyProfileContext.Provider>
    );
  }
}
const CompanyProfileConsumer = CompanyProfileContext.Consumer;

export { CompanyProfileProvider, CompanyProfileConsumer, CompanyProfileContext };

export function withCompanyProfileConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <CompanyProfileConsumer>
        {value => <Component {...props} context={value} />}
      </CompanyProfileConsumer>
    );
  };
}
