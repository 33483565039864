import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
 
import {
  
    AlertError,
} from '../../components/Utility/MyString';

const DB_RPT_Context = React.createContext();


const ServiceApi = BaseApi + "/api/sales/db_save";





export default class DB_RPT_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                group1_id: '',
                group1_name3: '',
                item_no: '',
                item_id: '',
                ischecked: 0,
                companycode: ''
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    group1_id: '',
                    group1_name3: '',
                    totalrecord: 1
                }],
            itempage2: [
                {
                    group1_id: '',
                    group1_name3: '',
                    totalrecord: 1
                }],
            itempage_kr1: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr2: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr3: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr4: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr5: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr6: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr7: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr8: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
            itempage_kr9: [
                {
                    inputdate: '',
                    db: '',
                    active: '',
                    avg: '',
                    totalrecord: 1
                }],
                itempage_ahot: [
                    {
                        inputdate: '',
                        db: '',
                        active: '',
                        avg: '',
                        totalrecord: 1
                    }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            address_postcode: '',
            ext_car_brand: '',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            count_all: 0,
            count_db: 0,
            count_acard: 0,
            count_booking: 0,
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }




    QMS_CUSDB_GROUPBY_TEAM = async (callBack) => {
        try {


            let page = {
                team_id: sessionStorage.getItem("team_id"),
                job_id: sessionStorage.getItem("job_id"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/qms_cusdb_groupby_team", { data: page })
                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })




        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };





    QMS_CUSDB_GROUPBY_SALES = async (callBack) => {
        try {


            let page = {
                team_id: sessionStorage.getItem("team_id"),
                job_id: sessionStorage.getItem("job_id"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/qms_cusdb_groupby_sales", { data: page })
                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage2: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })




        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };





    TNP_RPT_KR1 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr1"),
                enddate: sessionStorage.getItem("strenddate_kr1"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr1", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr1: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR2 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr2"),
                enddate: sessionStorage.getItem("strenddate_kr2"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr2", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr2: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })




        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR3 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr3"),
                enddate: sessionStorage.getItem("strenddate_kr3"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr3", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr3: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR4 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr4"),
                enddate: sessionStorage.getItem("strenddate_kr4"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr4", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr4: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR5 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr5"),
                enddate: sessionStorage.getItem("strenddate_kr5"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr5", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr5: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR6 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr6"),
                enddate: sessionStorage.getItem("strenddate_kr6"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr6", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr6: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    TNP_RPT_KR7 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr7"),
                enddate: sessionStorage.getItem("strenddate_kr7"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr7", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr7: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    TNP_RPT_KR8 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr8"),
                enddate: sessionStorage.getItem("strenddate_kr8"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr8", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr8: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    TNP_RPT_KR9 = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr9"),
                enddate: sessionStorage.getItem("strenddate_kr9"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/sales/qms_tnp_rpt_kr9", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_kr9: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    TNP_RPT_AHOT = async (callBack) => {
        try {


            let page = {
                begindate: sessionStorage.getItem("strfromdate_kr9"),
                enddate: sessionStorage.getItem("strenddate_kr9"),
                itemuserid: sessionStorage.getItem("itemuserid"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }
            await axios.post(BaseApi + "/api/acard/qms_tnp_rpt_ahot", { data: page })

                .then((response) => {

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_ahot: response.data
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)
                })

        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    render() {
        return (
            <DB_RPT_Context.Provider
                value={{
                    ...this.state,

                    QMS_CUSDB_GROUPBY_TEAM: this.QMS_CUSDB_GROUPBY_TEAM,
                    QMS_CUSDB_GROUPBY_SALES: this.QMS_CUSDB_GROUPBY_SALES,
                    TNP_RPT_KR1: this.TNP_RPT_KR1,
                    TNP_RPT_KR2: this.TNP_RPT_KR2,
                    TNP_RPT_KR3: this.TNP_RPT_KR3,
                    TNP_RPT_KR4: this.TNP_RPT_KR4,
                    TNP_RPT_KR5: this.TNP_RPT_KR5,
                    TNP_RPT_KR6: this.TNP_RPT_KR6,
                    TNP_RPT_KR7: this.TNP_RPT_KR7,
                    TNP_RPT_KR8: this.TNP_RPT_KR8,
                    TNP_RPT_KR9: this.TNP_RPT_KR9,
                    TNP_RPT_AHOT: this.TNP_RPT_AHOT,

                }}
            >
                {this.props.children}
            </DB_RPT_Context.Provider>

        );
    }
}
const DB_RPT_Consumer = DB_RPT_Context.Consumer;

export { DB_RPT_Provider, DB_RPT_Consumer, DB_RPT_Context };

export function withDB_RPT_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <DB_RPT_Consumer>
                {value => <Component {...props} context={value} />}
            </DB_RPT_Consumer>
        );
    };
}

