import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../Api/BaseApi";
import { message } from 'antd'
import {
    AlertCompleSave,
    AlertError,
} from '../components/Utility/MyString';

const Productlistbymodel_SurekContext = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/stock/Productliststock_surek_page";





export default class Productlistbymodel_SurekProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                vendorid: '',
                vendorname: '',
                curentaddress: '',
                telephone: '',
                faxphone: '',
                mobilephone: '',
                // activestatus: '1',
                vendornote: '01'
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    vendorid: '',
                    vendorname: '',
                    curentaddress: '',
                    telephone: '',
                    faxphone: '',
                    mobilephone: '',
                    vendornote: '01',
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            companycode: '01',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPageModel = async (id,callBack) => {
        try {



        let companycode = sessionStorage.getItem("branchcode")

        // alert("product context getpage productlistmodel by brand  1-> "+ companycode + id);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: id,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stock/Productlistbymodel_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context report getpage productlistbybrand 3  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getAllMenu = async (callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.get(url, { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getMenubyUserid = async (userid, callBack) => {
        try {

            //alert(userid);
            let url = BaseApi + "/api/dealer/dealer_user_menu"

            await axios.post(url, { data: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    }

    getData = async (userid, callBack) => {
        try {

            // alert("sssss"  + userid);
            //alert("service api ->" + ServiceApi)

            await axios.get(ServiceApi, { params: { code: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

    getUserAdmin = async (userid, callBack) => {
        try {

            //alert(userid);

            await axios.get(ServiceApi, { params: { userid: userid } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack("")
        }
    };

    getAll = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/dealer/dealer_user_all", { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //alert("user context save -> " + ServiceApi)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    setLogin = (userid, password, callBack) => {

        //submit to server

        this.setState({
            isLogin: true,
            data: {
                userid: userid,
                password: password
            }

        })

        this.getData(userid, callBack)

        //alert("Login->" + userid + "  password->" + password)

        //callBack()
    }

    setLogout = (callBack) => {
        this.setState({
            isLogin: false,
            data: {
                userid: '',
                password: ''
            }
        })

        callBack()
    }

    saveAuthorize = async (xdata, callBack) => {

        try {

            let url = BaseApi + "/api/dealer/dealer_authorize"
            //let url = BaseApi + "/a"
            //AlertError(JSON.stringify(xdata))

            await axios.post(url, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            callBack(true)
                        }, 2000)

                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }
 


    

    getSummary = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stock/productcurrentstock_page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //   alert("context report summary by month- getSummary  ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    //function
    componentDidMount() {
        //this.getData();    
    }


    getPagebrand = async (id,callBack) => {
        try {


        //  alert(' getPagebrand productlistbymodel_surekcontext =>' + id);
        

        let companycode = sessionStorage.getItem("branchcode")

        // alert("product context getpage productlistmodel by brand  1-> "+ companycode + id);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: id,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/stock/Productlistmodelbybrand_surek_page";

    //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

            //   alert("context report getpage productlistmodel  by brand  3  ->" + JSON.stringify(response.data));

                    // let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    // let ypage = response.data[0].totalrecord % 20;
                    // if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            // totalrecord: response.data[0].totalrecord,
                            // pagecount: xpagecount
                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };


    render() {
        return (
            <Productlistbymodel_SurekContext.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    getPagebrand:this.getPagebrand,
                    getPageModel: this.getPageModel,
                    getSummary: this.getSummary, 
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    setLogin: this.setLogin,
                    setLogout: this.setLogout,
                    getAll: this.getAll,
                    getUserAdmin: this.getUserAdmin,
                    updateData: this.updateData,
                    getAllMenu: this.getAllMenu,
                    getMenubyUserid: this.getMenubyUserid,
                    saveAuthorize: this.saveAuthorize
                }}
            >
                {this.props.children}
            </Productlistbymodel_SurekContext.Provider>
            
        );
    }
}
const Productlistbymodel_SurekConsumer = Productlistbymodel_SurekContext.Consumer;

export { Productlistbymodel_SurekProvider, Productlistbymodel_SurekConsumer, Productlistbymodel_SurekContext };

export function withProductlistbymodel_SurekConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Productlistbymodel_SurekConsumer>
                {value => <Component {...props} context={value} />}
            </Productlistbymodel_SurekConsumer>
        );
    };
}

