import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";
 
import {
 
    AlertError,
} from '../../components/Utility/MyString';

const Address_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/address/address_save";





export default class Address_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: { 
                item_no:'', 
                companycode:'' 
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    item_no:'', 
                    address_no:'',
                    address_road:'',
                    address_tumbon:'',
                    address_amphur:'',
                    address_province:'',
                    address_postcode:'',
                    telephone:'',
                    mobilephone:'',
                   
                    companycode:'' ,
                    totalrecord: 1
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            address_postcode:'',
            ext_car_brand:'',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_page";

       //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                //  alert("context Address  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    // }, 100)


                    // setTimeout(function () {
                        callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

     
    
    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)


            

            await axios.get(BaseApi + "/api/sales/acard_getbyid", { params: { code: code } })
                .then((response) => {

                // AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };

   
    getAll = async (callBack) => {
        try {

            //alert(userid);

            await axios.get(BaseApi + "/api/dealer/dealer_user_all", { params: { userid: "" } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

        //    alert("Address context save -> " + xdata)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    
    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getSummary(callBack)
        }, 200)

    }
 
 


    getprovince = async (callBack) => {
        try {

            //  alert("getprovince");
 
             let page = {
                 code : "" 
                 
               }
               


            await axios.post(BaseApi + "/api/address/getprovince", {   data : page })
                .then((response) => {

            //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                 
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                  
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getDistrict = async (callBack) => {
        try {

            let ProvinceThai = sessionStorage.getItem("ProvinceThai")

            let page = {
                code : ProvinceThai 
                
              }
 
            await axios.post(BaseApi + "/api/address/getdistrict", {   data : page })
                .then((response) => {

        // alert("context event get getAcardlist from server->" + JSON.stringify(response.data));
  
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data 
                            
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getTambon = async (callBack) => {
        try {

            let DistrictThai = sessionStorage.getItem("DistrictThai")

            let page = {
                code : DistrictThai 
                
              }
 
            await axios.post(BaseApi + "/api/address/gettambon", {   data : page })
                .then((response) => {

         
        // sessionStorage.setItem("geteventnext",  response.data.code)
                  
                            let obj = this

                            setTimeout(function () {
                                obj.setState({
                                    ...obj.state,
                                    itempage: response.data  
                            
                                });
                            }, 100)
                
            
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getPostcode = async (callBack) => {
        try {

            let tumbonid = sessionStorage.getItem("TambonID")
            let districtthai = sessionStorage.getItem("DistrictThai")

            let page = {
                code : tumbonid 
                ,districtthai :districtthai
                
              }
 
            await axios.post(BaseApi + "/api/address/getpostcode", {   data : page })
                .then((response) => {

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    }; 

    render() {
        return (
            <Address_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData, 
                    deleteData: this.deleteData,
                    getPage: this.getPage, 
                    getprovince:this.getprovince,
                    getDistrict: this.getDistrict,
                    getTambon:this.getTambon,
                    getPostcode:this.getPostcode, 
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage, 
                    getAll: this.getAll, 
                    updateData: this.updateData,
             
                 
                }}
            >
                {this.props.children}
            </Address_Context.Provider>
            
        );
    }
}
const Address_Consumer = Address_Context.Consumer;

export { Address_Provider, Address_Consumer, Address_Context };

export function withAddress_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Address_Consumer>
                {value => <Component {...props} context={value} />}
            </Address_Consumer>
        );
    };
}

